
import ProjectVisualSummary from "@/components/admin/ProjectVisualSummary.vue";
import StateBadge from "@/components/platform/StateBadgeComponent.vue";
import ComparisonRadarChartComponent, { ComparisonAssessmentItem, ComparisonBenchmarkItem } from "@/components/admin/ComparisonComponents/ComparisonRadarChartComponent.vue";
import CompetencyMatrix, { MatrixView } from "@/components/admin/CompetencyMatrix.vue";

import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { TypedVue } from "@/store/types";
import { ProjectAggregates, ProjectAssessment, SchemasV2SystemAssessmentsReport } from "@/lib/serviceTypes";
import { Benchmark, ProjectReportShort, V2Assessment, V2AssessmentWithScoring } from "@/store/admin/adminTypes";
import { V2Project, Assessment, ProjectTheme } from "@/store/admin/adminTypes";
import { Component } from "vue-property-decorator";
import { Getters } from "@/store/admin/adminGetters";
import moment from "moment-mini";
import { Actions } from "@/store/admin/adminActions";
import { ColorMap } from "@/lib/charts/HighChartThemes";
import { Trait } from "@/lib/consts";

const namespace = "admin";
export interface SummaryAssessment extends Assessment {
  shortId: string;
  pinned: boolean;
}

@Component({
  components: {
    ProjectVisualSummary,
    ComparisonRadarChartComponent,
    StateBadge,
    CompetencyMatrix,
  },
})
export default class SummaryAllProjects extends TypedVue {
  summaryData: any = {};

  // assessments: any[] = assessmentData;
  showNames = false;
  matrixView: MatrixView = "top-priority";
  matrixFilterText = "";

  searchText = "";
  filter: string | null = null;
  filterOptions = [
    { value: null, text: "All" },
    { value: "incomplete", text: "Incomplete" },
    { value: "in-progress", text: "In Progress" },
    { value: "scoring_complete", text: "Pending" },
    { value: "report-generated", text: "Complete" },
  ];

  isLoading = true;
  projectAssessments: V2Assessment[] = [];
  comparisonCandidates: ComparisonAssessmentItem[] = Vue.observable([]);
  comparisonBenchmarks: ComparisonBenchmarkItem[] = Vue.observable([]);

  @Getter(Getters.GET_CURRENT_PROJECT, { namespace })
  public currentProject!: V2Project | null;

  @Getter(Getters.GET_CURRENT_PROJECT_SHORT_ASSESSMENTS, { namespace })
  public assessments!: ProjectAssessment[];

  @Getter(Getters.GET_CURRENT_PROJECT_SHORT_REPORTS, { namespace })
  public projectReports!: ProjectReportShort[];

  @Getter(Getters.GET_CURRENT_PROJECT_THEME, { namespace })
  public projectTheme!: ProjectTheme;

  @Getter(Getters.GET_CURRENT_PROJECT_AGGREGATES, { namespace })
  public projectAggregates!: ProjectAggregates;

  @Getter(Getters.CURRENT_PROJECT_DIVERSITY_ENABLED, { namespace })
  public projectHasDiversity!: boolean;

  @Getter(Getters.GET_CURRENT_PROJECT_BENCHMARKS, { namespace })
  public projectBenchmarks!: Benchmark[];

  @Getter(Getters.GET_CURRENT_PROJECT_ROOT_BENCHMARK_ID, { namespace })
  public rootBenchmarkId!: string;

  @Action(Actions.GET_PROJECT_ASSESSMENTS, { namespace })
  getProjectAssessments!: (projectId: string | null) => Promise<V2Assessment[]>;

  @Action(Actions.CHANGE_PROJECT, { namespace })
  changeProject!: (project: V2Project | undefined) => Promise<void>;

  get filteredAssessments(): any[] {
    return this.assessments
      .filter((assessment) => {
        if (this.searchText) {
          return assessment.candidate.name.toLowerCase().includes(this.searchText.toLowerCase());
        }
        if (this.filter) {
          if (this.filter === "scoring_complete") {
            return assessment.state === this.filter || assessment.state === "complete";
          }
          return assessment.state === this.filter;
        }
        return true;
      })
      .sort((a, b) => {
        return a.candidate.name < b.candidate.name ? -1 : 1;
        // if (this.filterOptions.findIndex((o) => o.value === a.state) > this.filterOptions.findIndex((o) => o.value === b.state)) {
        //   return -1;
        // }
        // if (this.filterOptions.findIndex((o) => o.value === a.state) < this.filterOptions.findIndex((o) => o.value === b.state)) {
        //   return 1;
        // }
        // return 0;
      });
  }

  get assessmentsWithScoring(): V2AssessmentWithScoring[] {
    return this.projectAssessments
      .filter((a) => this.matrixFilterText === "" || a.candidate.name.toLowerCase().includes(this.matrixFilterText.toLowerCase()))
      .map((a) => {
        const report = a.report as SchemasV2SystemAssessmentsReport;
        return {
          ...a,
          scoring: {
            readiness: report?.readiness,
            trait_statuses: report?.traitStatuses || {},
            trait_scores: report?.traitScores || {},
            competencies: report?.competencies || {},
            competency_scores: report?.competencyScores || {},
          },
        };
      });
  }

  get currentProjectId(): string {
    if (this.currentProject) {
      return this.currentProject.identifier;
    }
    return "";
  }

  get userIsAdmin(): boolean {
    const user = this.$auth.user;
    if (user) {
      return user.isOrgAdmin;
    }
    return false;
  }

  get organizationProjects(): V2Project[] {
    return this.$store.state.admin.organizationProjects.filter((p) => p.reports && p.reports.length > 0);
  }

  get traitClassRanges(): Record<Trait, { low: number; high: number; lowerlow: number; mean: number; standardDeviation: number }> {
    const rootBenchamrk = this.projectBenchmarks.find((b) => b.benchmarkId === this.rootBenchmarkId);
    if (rootBenchamrk && rootBenchamrk.traitCutoffs) {
      return rootBenchamrk.traitCutoffs as Record<Trait, { low: number; high: number; lowerlow: number; mean: number; standardDeviation: number }>;
    }
    return {} as Record<Trait, { low: number; high: number; lowerlow: number; mean: number; standardDeviation: number }>;
  }

  async mounted() {
    // Get assments with scoring
    this.projectAssessments = await this.getProjectAssessments(null);

    this.comparisonCandidates = Vue.observable<ComparisonAssessmentItem[]>(
      this.projectAssessments.map<ComparisonAssessmentItem>((c) => ({
        data: {
          assessmentId: c.identifier,
          project: c.projectId,
          candidateName: c.candidate.name,
          isComplete: c.report !== undefined,
          readinessScore: c.report?.readiness || "default",
          traits: c.report?.traitStatuses || {},
          traitScores: c.report?.traitScores || {},
          competencies: c.report?.competencies || {},
          competencyScores: c.report?.competencyScores || {},
        },
        id: c.identifier,
        hidden: false,
        highlighted: false,
        locked: false,
        selected: false,
        color: ColorMap[this.projectTheme][(c.report?.readiness as string) ?? "default"],
        initialColor: ColorMap[this.projectTheme][(c.report?.readiness as string) ?? "default"],
      })),
    );
    this.comparisonBenchmarks = this.projectBenchmarks.map<ComparisonBenchmarkItem>((benchmark) => ({
      data: { ...benchmark, default: this.rootBenchmarkId === benchmark.benchmarkId || false },
      id: benchmark.benchmarkId,
      hidden: false,
      highlighted: false,
      default: this.rootBenchmarkId === benchmark.benchmarkId || false,
      selected: this.rootBenchmarkId === benchmark.benchmarkId || false,
      locked: false,
      color: benchmark.colour || ColorMap[this.projectTheme].default,
      initialColor: benchmark.colour || ColorMap[this.projectTheme].default,
    }));

    this.isLoading = false;
  }

  getReportProject(report: ProjectReportShort): V2Project | undefined {
    const project = this.organizationProjects.find((p) => p.reports?.find((r) => r.identifier === report.identifier));
    return project;
  }

  toFriendlyDate(date: string): string {
    const mDate = moment.utc(date).local();
    return mDate.format("MMMM Do, YYYY");
  }

  async selectReportProject(report: ProjectReportShort) {
    const project = this.getReportProject(report);
    await this.changeProject(project);
  }
}
