
import { TypedVue } from "@/store/types";
import { Project, Benchmark, Organization } from "@/store/admin/adminTypes";
import { Component } from "vue-property-decorator";
import ProjectDropdown from "@/components/input/ProjectDropdown.vue";
import Multiselect from "vue-multiselect";
import adminService from "@/services/adminService";

interface ProjectOption {
  orgName: string;
  orgId: string;
  projects: Project[];
}

@Component({
  components: {
    Multiselect,
    ProjectDropdown,
  },
})
export default class BenchmarkList extends TypedVue {
  benchmarks: Benchmark[] = [];
  projects: Project[] = [];
  benchmarkProjects: Project[] = [];
  organizations: Organization[] = [];
  scoringVersions: string[] = [];
  shortAssessments: { id: string; name: string; org: string; project: string }[] = [];

  currentPage = 1;
  perPage = 20;

  scoresLoading = false;
  benchmarksLoading = true;
  versionsLoading = false;
  errorMsg = "";
  errorActive = false;

  selectedBenchmark: Benchmark = this.createEmptyBench();
  selectedProjects: Project[] = [];
  selectedAssessmentIds: { label: string; value: string }[] = [];
  selectedScoringVersion = "";
  selectedOrgFilter = "";

  modalActive = false;
  modalButtonsEnabled = true;
  searchText = "";

  fields = [
    { key: "ID", sortable: true },
    { key: "name", sortable: true },
    { key: "description", sortable: false },
    { key: "friendlyOrgName", sortable: true, label: "Organization" },
    {
      key: "dateCreated",
      sortable: true,
      formatter: function (value: string): string {
        if (!value) return "-";
        return new Date(value + "Z").toDateString();
      },
    },
    {
      key: "lastUpdated",
      sortable: true,
      formatter: function (value: string): string {
        if (!value) return "-";
        return new Date(value + "Z").toDateString();
      },
    },
    "actions",
  ];

  competencyGroupings = ["dedicated", "principled", "influential", "insightful", "adaptable", "people-oriented", "action-oriented"];
  competencies = ["goal", "decisive", "adaptability", "con", "grit", "tact", "persuade", "strategic", "intellect", "vision", "empathy", "mentor", "relationship", "integrity", "auth"];

  get globalBenchmark(): boolean {
    return this.selectedBenchmark.organization ? false : true;
  }

  set globalBenchmark(value: boolean) {
    if (value) {
      this.selectedBenchmark.organization = undefined;
    } else {
      this.selectedBenchmark.organization = [];
    }
  }

  get projectChoice(): Project[] {
    return this.selectedProjects;
  }

  set projectChoice(projects: Project[]) {
    this.selectedProjects = projects;
  }

  get projectOptions(): ProjectOption[] {
    const orgProjects: Record<string, Project[]> = {};

    for (const project of this.projects) {
      if (!orgProjects[project.organization]) {
        orgProjects[project.organization] = [project];
      } else {
        orgProjects[project.organization].push(project);
      }
    }

    const projectOptions: ProjectOption[] = [];
    for (const key of Object.keys(orgProjects)) {
      const org = this.organizations.find((o) => o.id === key);
      const text = `${org?.displayName ? org?.displayName : "N/A"} - ${org?.id ? org?.id : "N/A"}`;

      projectOptions.push({
        orgName: text,
        orgId: org?.id ? org.id : "",
        projects: orgProjects[key],
      });
    }

    return projectOptions;
  }

  get orgOptions(): string[] {
    return this.organizations.map((org) => {
      return org.id;
    });
  }

  get namedOrgOptions(): { text: string; value: string }[] {
    return this.organizations.map((org) => {
      return {
        text: org.displayName,
        value: org.id,
      };
    });
  }

  get competencyGroupingRows(): string[][] {
    const rows: string[][] = [];
    for (let i = 0; i < this.competencyGroupings.length; i += 4) {
      const chunk = this.competencyGroupings.slice(i, i + 4);
      rows.push(chunk);
    }

    return rows;
  }

  get competencyRows(): string[][] {
    const rows: string[][] = [];
    for (let i = 0; i < this.competencies.length; i += 5) {
      const chunk = this.competencies.slice(i, i + 5);
      rows.push(chunk);
    }

    return rows;
  }

  get customBenchmarkOptions(): { groupId: string; groupName: string; values: { label: string; value: string }[] }[] {
    const options = [] as { groupId: string; groupName: string; values: { label: string; value: string }[] }[];

    if (this.selectedOrgFilter === "") {
      for (const org of this.organizations) {
        options.push({
          groupId: org.id,
          groupName: "Org: " + org.displayName,
          values: this.shortAssessments
            .filter((assessment) => assessment.org === org.id)
            .map((assessment) => {
              return {
                label: assessment.id.slice(0, 4) + " - " + assessment.name,
                value: assessment.id,
              };
            }),
        });
      }
    } else {
      const projects = this.projects.filter((project) => project.organization === this.selectedOrgFilter);
      for (const project of projects) {
        options.push({
          groupId: project.projectId,
          groupName: "Project: " + project.name,
          values: this.shortAssessments
            .filter((assessment) => assessment.project === project.projectId)
            .map((assessment) => {
              return {
                label: assessment.id.slice(0, 4) + " - " + assessment.name,
                value: assessment.id,
              };
            }),
        });
      }
    }

    return options;
  }

  get assessmentsInBenchmark(): { id: string; shortId: string; name: string }[] {
    const ids = this.selectedBenchmark.assessments || [];
    return this.shortAssessments
      .filter((assessment) => ids.includes(assessment.id))
      .map((assessment) => {
        return {
          id: assessment.id,
          shortId: assessment.id.slice(0, 4),
          name: assessment.name,
        };
      });
  }

  async mounted(): Promise<void> {
    await this.getOrganizations();
    await this.getProjects();
    await this.getAssessmentIds();
    await this.getBenchmarks();
    await this.getScoringVersions();
  }

  customLabel(project: Project): string {
    return project.name;
  }

  organizationName(orgId: string): string {
    return this.organizations.find((org) => org.id === orgId)?.displayName || orgId;
  }

  createEmptyBench(): Benchmark {
    return {
      benchmarkId: "",
      name: "",
      colour: "",
      organization: undefined,
      alwaysShow: false,
      competencyScores: {},
      traitScores: {},
      isAdmin: false,
    };
  }

  async deleteBenchmark(): Promise<void> {
    this.modalButtonsEnabled = false;
    if (confirm("Are you sure you want to delete this benchmark?")) {
      await adminService.deleteBenchmark(this.selectedBenchmark.benchmarkId);
      await this.getBenchmarks();
      this.selectedBenchmark = this.createEmptyBench();
      this.modalActive = false;
    }
    this.modalButtonsEnabled = true;
  }

  async editBenchmark(event: Event): Promise<void> {
    event.preventDefault();
    this.modalButtonsEnabled = false;

    if (this.selectedBenchmark.benchmarkId === "") {
      if (confirm("Are you sure you want to create this benchmark?")) {
        await this.createBenchmark();
        await this.getBenchmarks();
      }
    } else {
      if (confirm("Are you sure you want to edit this benchmark?")) {
        await this.updateBenchmark();
        await this.getBenchmarks();
      }
    }

    this.modalButtonsEnabled = true;
    this.modalActive = false;
  }

  async updateBenchmark(): Promise<void> {
    const benchmark = this.selectedBenchmark;
    const benchmarkProjects = this.benchmarkProjects;
    const selectedProjects = this.selectedProjects;

    await adminService.updateBenchmark(benchmark);

    // Unassign benchmark from projects as needed
    for (const benchmarkProject of benchmarkProjects) {
      const index = selectedProjects.findIndex((selectedProject: Project) => selectedProject.projectId === benchmarkProject.projectId);
      if (index === -1) {
        await adminService.removeBenchmarkFromProject(benchmark.benchmarkId, benchmarkProject.projectId);
      }
    }

    // Add benchmark to projects as needed
    for (const selectedProject of selectedProjects) {
      const index = benchmarkProjects.findIndex((benchmarkProject: Project) => selectedProject.projectId === benchmarkProject.projectId);
      if (index === -1) {
        await adminService.addBenchmarkToProject(benchmark.benchmarkId, selectedProject.projectId);
      }
    }
  }

  async createBenchmark(): Promise<void> {
    await adminService.createBenchmark(this.selectedBenchmark);
  }

  async getBenchmarks(): Promise<void> {
    this.benchmarksLoading = true;
    await adminService
      .getAllBenchmarks()
      .then((res) => {
        this.benchmarks = res.map((benchmark) => {
          return {
            ...benchmark,
            ID: benchmark.benchmarkId.slice(0, 4),
            friendlyOrgName: benchmark.organization ? benchmark.organization.map((b) => this.organizations.find((org) => org.id === b)?.displayName || benchmark.organization).join("\n") : "All",
          };
        });
      })
      .finally(() => {
        this.benchmarksLoading = false;
      });
  }

  async getOrganizations(): Promise<void> {
    this.organizations = await adminService.getOrganizations();
  }

  async getProjects(): Promise<void> {
    await adminService.getProjects(null, null, false).then((res) => {
      this.projects = res;
    });
  }

  async getProjectsForBenchmark(benchmarkId: string): Promise<void> {
    await adminService.getProjectsForBenchmark(benchmarkId).then((res) => {
      this.selectedProjects = res;
      this.benchmarkProjects = res;
    });
  }

  async getAssessmentIds(): Promise<void> {
    await adminService.getShortAssessments(true).then((assessments) => {
      this.shortAssessments = assessments.map((assessment) => {
        return {
          id: assessment[0],
          name: assessment[1],
          org: assessment[2],
          project: assessment[3],
        };
      });
    });
  }

  async getScoringVersions() {
    this.versionsLoading = true;
    this.scoringVersions = await adminService.getScoringVersions().finally(() => {
      this.versionsLoading = false;
    });
    if (this.scoringVersions.length) {
      this.selectedScoringVersion = this.scoringVersions[0];
    }
  }

  async calculateAverageScores(): Promise<void> {
    this.scoresLoading = true;
    this.modalButtonsEnabled = false;
    await adminService
      .generateBenchmark(
        this.selectedAssessmentIds.map((a) => a.value),
        this.selectedScoringVersion,
      )
      .then((result) => {
        if (result.success) {
          this.selectedBenchmark = {
            ...this.selectedBenchmark,
            competencyScores: result.competencyScores as Record<string, number>, // TODO: Fix type
            traitScores: result.traitScores as Record<string, number>, // TODO: Fix type
            assessments: this.selectedAssessmentIds.map((a) => a.value),
          };
        } else {
          this.showAlert(result.detail);
        }
      })
      .finally(() => {
        this.scoresLoading = false;
        this.modalButtonsEnabled = true;
      });
  }

  async showModal(item: Benchmark): Promise<void> {
    this.selectedBenchmark = { ...item };

    if (item.benchmarkId !== "") {
      await this.getProjectsForBenchmark(item.benchmarkId);
    }

    this.modalActive = true;
  }

  showModalCreate(): void {
    this.selectedBenchmark = this.createEmptyBench();

    this.modalActive = true;
  }

  closeModal(): void {
    this.selectedBenchmark = this.createEmptyBench();
    this.selectedProjects = [];
    this.selectedAssessmentIds = [];
    this.modalActive = false;
    this.errorMsg = "";
  }

  showAlert(msg: string): void {
    this.errorMsg = msg;
    this.errorActive = true;
    setTimeout(() => {
      this.errorActive = false;
      this.errorMsg = "";
    }, 8000);
  }
}
