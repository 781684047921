
import { TypedVue } from "@/store/types";
import { Actions } from "@/store/assessment/actions";
import { Component } from "vue-property-decorator";
import { Action } from "vuex-class";

const namespace = "assessment";

@Component({})
export default class IdentityCheck extends TypedVue {
  @Action(Actions.UPDATE_ASSESMENT_DATA, { namespace })
  updateAssessmentData!: (data: Record<string, any>) => void;

  get candidateName(): string {
    return this.$store.state.assessment.assessmentStatusSummary?.candidateName ?? "";
  }

  get candidateEmail(): string {
    return this.$store.state.assessment.assessmentStatusSummary?.candidateEmail ?? "";
  }

  async submitIdentityCheck(value: boolean): Promise<void> {
    if (!value) {
      this.updateAssessmentData({ identityConfirmed: false });
    }
    this.next();
  }

  async next(): Promise<void> {
    const lang = this.$route.query.lang;
    const subtitles = this.$route.query.subtitles;

    if (this.$store.state.assessment.assessmentStatusSummary?.agreedToTermsAndConditions ?? false) {
      await this.$router.push({
        path: "assessment",
        query: { lang, subtitles },
      });
    } else {
      await this.$router.push({
        path: "Agreements",
        query: { lang, subtitles },
      });
    }
  }
}
