
import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import { TypedVue } from "@/store/types";
import { TRAITS, Trait } from "@/lib/consts";
import { V2AssessmentWithScoring } from "@/store/admin/adminTypes";
import CandidateTag from "@/components/platform/CandidateTag.vue";

export type MatrixView = "top-priority" | "must-have" | "custom";

@Component({ components: { CandidateTag } })
export default class CompetencyMatrix extends TypedVue {
  @Prop() traitPriorities!: Record<string, number>;
  @Prop() assessments!: V2AssessmentWithScoring[];
  @Prop() showNames!: boolean;
  @Prop() showChampions!: boolean;
  @Prop() traitClassRanges!: Record<Trait, { low: number; high: number; lowerlow: number; mean: number; standardDeviation: number }>;
  @Prop({ default: "project-report-matrix" }) cacheKey!: string;
  @Prop({ default: "top-priority" }) matrixView!: MatrixView;
  @Prop({ default: null }) allowedCompetencies!: Trait[];
  @Prop({ default: () => ({}) }) tags!: Record<string, string[]>;

  @Ref("matrix-table") matrixTable!: HTMLTableElement;
  @Ref("draggable-header") draggableHeader!: HTMLDivElement;
  @Ref("draggable-position-indicator") draggablePositionIndicator!: HTMLDivElement;

  public isDragging = false;
  public draggingHeader: HTMLElement | null = null;
  public orderedHeaders: string[] = [];
  public lastDraggedOverHeader: HTMLElement | null = null;
  public headerElements: HTMLElement[] = [];
  public draggableText = "";
  public eventsRegistered = false;
  public pinnedAssessments = [] as string[];

  get orderedAssessments() {
    return this.assessments.sort((a, b) => {
      if (this.pinnedAssessments.includes(a.identifier) && !this.pinnedAssessments.includes(b.identifier)) {
        return -1;
      } else if (this.pinnedAssessments.includes(b.identifier) && !this.pinnedAssessments.includes(a.identifier)) {
        return 1;
      } else if (this.pinnedAssessments.includes(a.identifier) && this.pinnedAssessments.includes(b.identifier)) {
        const value = this.pinnedAssessments.indexOf(a.identifier) - this.pinnedAssessments.indexOf(b.identifier);
        if (value !== 0) {
          return value > 0 ? 1 : -1;
        }
        return value;
      }

      if (a.report && !b.report) {
        return -1;
      } else if (!a.report && b.report) {
        return 1;
      } else if (a.report && b.report) {
        return this.getTotalCompetencyPrioritized(b) - this.getTotalCompetencyPrioritized(a);
      } else {
        return this.getStateValue(b.state) - this.getStateValue(a.state);
      }
    });
  }

  get allowReorder() {
    return this.matrixView === "custom";
  }

  get sortMatrixByMustHaves() {
    return this.matrixView === "must-have";
  }

  @Watch("pinnedAssessments")
  public onPinnedAssessmentsChange(newVal: string[]) {
    window.localStorage.setItem(`${this.cacheKey}-pinned`, newVal.join(","));
  }

  @Watch("allowedCompetencies")
  public onAllowedCompetenciesChange() {
    this.refreshView();
  }

  @Watch("matrixView")
  public onMatrixViewChange() {
    this.refreshView();
  }

  @Watch("assessments")
  public onAssessmentsChange() {
    this.refreshView();
  }

  @Watch("orderedHeaders")
  public onOrderedHeadersChange() {
    this.$nextTick(() => {
      this.headerElements = Array.from(this.matrixTable.querySelectorAll("thead th.trait-header"));
      if (!this.eventsRegistered) {
        this.matrixTable.querySelectorAll("thead th.trait-header").forEach((el) => {
          el.removeEventListener;
          el.addEventListener("mousedown", (event: Event) => {
            if (this.matrixView !== "custom") {
              return;
            }

            const mouseEvent = event as MouseEvent;
            this.draggingHeader = el as HTMLElement;
            this.draggableHeader.innerText = this.draggingHeader.innerText;

            this.$nextTick(() => {
              this.draggableHeader.style.left = `${mouseEvent.pageX - this.draggableHeader.clientWidth / 2.75}px`;
              this.draggableHeader.style.top = `${mouseEvent.pageY - this.draggableHeader.clientHeight / 2.75}px`;
            });

            this.isDragging = true;
            this.draggingHeader.classList.add("highlight");
          });
        });
        this.eventsRegistered = true;
      }
    });
  }

  togglePinedAssessment(assessmentId: string) {
    if (!this.pinnedAssessments.includes(assessmentId)) {
      this.pinnedAssessments = [...this.pinnedAssessments, assessmentId];
    } else {
      this.pinnedAssessments = this.pinnedAssessments.filter((x) => x !== assessmentId);
    }
  }

  getTags(assessment: V2AssessmentWithScoring) {
    return (this.tags[assessment.identifier] ?? []).slice().sort();
  }

  refreshView() {
    if (this.matrixView === "top-priority" || this.matrixView === "must-have") {
      this.orderedHeaders = this.allowedCompetencies !== null && this.allowedCompetencies.length > 0 ? this.matrixTraits.filter((x) => this.allowedCompetencies.includes(x as Trait)) : this.matrixTraits;
    } else if (this.matrixView === "custom") {
      if (window.localStorage.getItem(this.cacheKey)) {
        const savedHeaders = window.localStorage.getItem(this.cacheKey)?.split(",") ?? [];
        this.orderedHeaders = savedHeaders.filter((x) => (this.allowedCompetencies && this.allowedCompetencies.length > 0 ? this.allowedCompetencies.includes(x as Trait) : true));
      } else {
        this.orderedHeaders = this.allowedCompetencies !== null && this.allowedCompetencies.length > 0 ? this.matrixTraits.filter((x) => this.allowedCompetencies.includes(x as Trait)) : this.matrixTraits;
      }
    }
  }

  mounted() {
    this.refreshView();
    console.log(this.orderedAssessments);

    document.addEventListener("mousemove", this.drag.bind(this));
    document.addEventListener("mouseup", this.stopDrag.bind(this));

    this.pinnedAssessments =
      window.localStorage
        .getItem(`${this.cacheKey}-pinned`)
        ?.split(",")
        .filter((x) => x.length > 0) ?? [];
  }

  public drag(event: MouseEvent) {
    if (this.isDragging) {
      const xpos = event.pageX;
      const ypos = event.pageY;
      this.draggableHeader.style.left = `${xpos - this.draggableHeader.clientWidth / 2.75}px`;
      this.draggableHeader.style.top = `${ypos - this.draggableHeader.clientHeight / 2.75}px`;

      const closestHeader =
        xpos < this.headerElements[0].getBoundingClientRect().x
          ? this.headerElements[0]
          : xpos > this.headerElements[this.headerElements.length - 1].getBoundingClientRect().x
          ? this.headerElements[this.headerElements.length - 1]
          : this.headerElements.find((el) => {
              const bounds = el.getBoundingClientRect();
              return xpos >= bounds.x && xpos <= bounds.x + bounds.width;
            });

      if (closestHeader && closestHeader !== this.lastDraggedOverHeader) {
        const draggedLeft = this.lastDraggedOverHeader ? this.headerElements.indexOf(closestHeader) <= this.headerElements.indexOf(this.lastDraggedOverHeader) : false;

        if (this.draggingHeader && this.draggingHeader !== closestHeader) {
          this.matrixTable.querySelectorAll("thead th.trait-header").forEach((el) => {
            el.classList.remove("last-moved-left");
            el.classList.remove("last-moved-right");
          });

          this.draggingHeader.parentElement?.removeChild(this.draggingHeader);
          this.lastDraggedOverHeader = (draggedLeft ? closestHeader : closestHeader.previousSibling) as HTMLElement;

          closestHeader.insertAdjacentElement(closestHeader === this.headerElements[this.headerElements.length - 1] ? "afterend" : "beforebegin", this.draggingHeader);
          this.lastDraggedOverHeader.classList.add(`last-moved-${draggedLeft ? "left" : "right"}`);
          this.headerElements = Array.from(this.matrixTable.querySelectorAll("thead th.trait-header"));
        }
      }
    }
  }

  public shadeLevelByMustHaves(trait: string) {
    const totalPScorePct = this.getTotalCompetencyByTraitPrioritized(trait) / this.assessments.length;
    return totalPScorePct >= 0.8 ? 2 : totalPScorePct >= 0.5 ? 1 : 0;
  }

  public stopDrag() {
    if (this.isDragging) {
      this.headerElements.forEach((el) => {
        el.classList.remove("highlight");
      });

      this.orderedHeaders = this.headerElements.map((el) => el.getAttribute("data-trait") as string);
      window.localStorage.setItem(this.cacheKey, this.orderedHeaders.join(","));
      this.isDragging = false;
    }
  }

  get matrixTraits() {
    const traits = TRAITS.map((x) => x as string);
    return traits.sort((a, b) => {
      if (this.matrixView === "must-have") {
        return this.traitPriorities[b] !== this.traitPriorities[a]
          ? (this.traitPriorities[b] ?? 0) - (this.traitPriorities[a] ?? 0)
          : this.getTotalCompetencyByTraitPrioritized(b as Trait) - this.getTotalCompetencyByTraitPrioritized(a as Trait);
      } else {
        return this.getTotalCompetencyByTraitPrioritized(b as Trait) - this.getTotalCompetencyByTraitPrioritized(a as Trait);
      }
    });
  }

  get traitChampions() {
    return TRAITS.map((t) => ({
      trait: t,
      max: this.assessments.reduce((a, x) => Math.max(a, ((x.scoring?.trait_scores as Record<string, any>)[t] as number) ?? 0), 0) || 0,
    })).reduce((a, t) => {
      a[t.trait] = t.max;
      return a;
    }, {} as Record<string, number>);
  }

  public getTotalCompetencyByTraitPrioritized(trait: string): number {
    return this.orderedAssessments.filter((a) => (a.scoring.trait_scores as Record<string, number>)[trait] >= this.traitClassRanges[trait as Trait].low).length ?? 0;
  }

  public getTraitClass(trait: string, assessment: V2AssessmentWithScoring): string {
    const range = this.traitClassRanges[trait as Trait];
    const score = (assessment.scoring.trait_scores as Record<string, number>)[trait];
    let classes = [];

    if (this.showChampions && score == this.traitChampions[trait]) {
      classes.push("champion");
    }

    if (score >= range.high) {
      classes.push("contextual");
    } else if (score >= range.low) {
      classes.push("moderated");
    } else if (score) {
      classes.push("low");
    }

    return classes.join(" ");
  }

  getStateName(state: string): string {
    switch (state) {
      case "incomplete":
        return "Incomplete";
      case "in-progress":
        return "In Progress";
      case "complete":
      case "scoring_complete":
        return "Pending";
      case "report-generated":
        return "Complete";
      default:
        return state;
    }
  }

  getStateValue(state: string): number {
    switch (state) {
      case "incomplete":
        return 0;
      case "in-progress":
        return 1;
      case "complete":
      case "scoring_complete":
        return 2;
      case "report-generated":
        return 3;
      default:
        return 0;
    }
  }

  public getTotalCompetencyPrioritized(assessment: V2AssessmentWithScoring): number {
    const trait_scores = assessment.scoring.trait_scores as Record<string, number>;
    return Object.keys(trait_scores).filter((trait) => (this.allowedCompetencies ? this.allowedCompetencies.includes(trait as Trait) : true) && trait_scores[trait] >= this.traitClassRanges[trait as Trait].low).length;
  }
}
