var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('table',{ref:"matrix-table",staticClass:"leader-table",class:{ 'is-dragging': _vm.isDragging, 'drag-enabled': _vm.allowReorder }},[_c('thead',[_c('tr',[_vm._m(0),_vm._l((_vm.orderedHeaders),function(trait){return _c('th',{key:trait,staticClass:"trait-header",class:!_vm.sortMatrixByMustHaves ? (_vm.allowReorder ? `header-shade-nonshade` : `header-shade-${_vm.shadeLevelByMustHaves(trait)}`) : `header-shade-${_vm.traitPriorities[trait]}`,attrs:{"data-trait":trait,"scope":"col"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t(`Comparison::TraitChart::XAxisLabel::${trait}`))+" ")])])}),_vm._m(1)],2)]),_c('tbody',_vm._l((_vm.orderedAssessments),function(assessment){return _c('tr',{key:assessment.identifier},[_c('td',[_c('div',{staticClass:"candidate-label"},[_c('a',{staticClass:"pin-row",attrs:{"href":"javsacript:void(0)"},on:{"click":function($event){return _vm.togglePinedAssessment(assessment.identifier)}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":_vm.pinnedAssessments.includes(assessment.identifier) ? 'pin-angle-fill' : 'pin-angle'}})],1),(
                //@ts-ignore
                assessment.reportId || assessment['report_id']
              )?_c('a',{staticClass:"view-report",attrs:{"href":`/admin/candidate/${assessment.identifier}`,"target":"_blank"}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"journal-text"}})],1):_vm._e(),_vm._v(" "+_vm._s(_vm.showNames ? assessment.candidate.name : assessment.identifier.substring(0, 4).toUpperCase())+" ")]),_c('div',{staticStyle:{"position":"relative"}},[_c('div',{class:`readiness-pill ${assessment.scoring?.readiness ? String(assessment.scoring.readiness).replaceAll(' ', '-') : 'incomplete'}-badge`},[_vm._v(" "+_vm._s(assessment.scoring?.readiness ? _vm.$t(`Report::Readiness::textShort::${assessment.scoring.readiness}`) : _vm.getStateName(assessment.state))+" ")])]),_c('div',{staticClass:"mt-2 d-flex tag-container"},_vm._l((_vm.getTags(assessment)),function(tag){return _c('CandidateTag',{key:tag,staticClass:"mr-2 text-capitalize",attrs:{"tag":tag}})}),1)]),_vm._l((_vm.orderedHeaders),function(trait){return _c('td',{key:trait},[_c('div',{staticClass:"competency-circle",class:_vm.getTraitClass(trait, assessment)})])}),_c('td',{staticClass:"table-tally"},[(
              //@ts-ignore
              assessment.reportId || assessment['report_id']
            )?[_c('span',[_vm._v(_vm._s(_vm.getTotalCompetencyPrioritized(assessment)))]),_vm._v("/"+_vm._s(_vm.orderedHeaders.length)+" ")]:[_vm._v(" - ")]],2)],2)}),0),_c('tfoot',[_c('tr',[_vm._m(2),_vm._l((_vm.orderedHeaders),function(trait){return _c('td',{key:trait},[_c('div',{staticClass:"table-tally"},[_c('span',[_vm._v(_vm._s(_vm.getTotalCompetencyByTraitPrioritized(trait)))]),_vm._v("/"+_vm._s(_vm.assessments.length)+" ")])])}),_c('td')],2)])]),_c('div',{ref:"draggable-header",staticClass:"draggable-header",class:{ active: _vm.isDragging }}),_c('div',{ref:"draggable-position-indicator",staticClass:"draggable-poistion-indicator"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',{attrs:{"scope":"col"}},[_c('div',[_vm._v("Person & Leadership Profile")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',{attrs:{"scope":"col"}},[_c('div',[_vm._v("Total Competencies Prioritized")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('b',[_vm._v("Group Prioritization")])])
}]

export { render, staticRenderFns }