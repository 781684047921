
import { Component } from "vue-property-decorator";
import VideoComponent from "@/components/VideoComponent.vue";
import { TypedVue, AssessmentStatus } from "@/store/types";
import { Action, Mutation } from "vuex-class";
import { Actions } from "@/store/assessment/actions";
import { Mutations } from "@/store/mutations";

const namespace = "assessment";

@Component({
  components: {
    VideoComponent,
  },
})
export default class WelcomeSplash extends TypedVue {
  @Action(Actions.GET_ASSESSMENT_STATUS_SUMMARY, { namespace })
  getAssessmentStatusSummary!: () => Promise<void>;

  @Mutation(Mutations.SET_ASSESSMENT_TOKEN, { namespace })
  setAssessmentToken!: (token: string) => void;

  @Action(Actions.GET_ASSESSMENT_SCHEMA, { namespace })
  getAssessmentSchema!: (lang: string) => Promise<void>;

  private readonly audioTestFile = "/assets/test_sound.mp3";

  public isResuming = false;
  public audioPlaying = false;
  public logoUrl = "";

  get candidateName(): string {
    return this.$store.state.assessment.assessmentStatusSummary?.candidateName ?? "";
  }

  get initiatingCompanyName(): string {
    return this.$store.state.assessment.assessmentStatusSummary?.hiringCompany ?? "";
  }

  get isAssessmentValid(): boolean {
    return this.$store.state.assessment.assessmentStatus === AssessmentStatus.ACTIVE;
  }

  get isAssessmentInvalid(): boolean {
    return this.$store.state.assessment.assessmentStatus === AssessmentStatus.INVALID;
  }

  get isAssessmentExpired(): boolean {
    return this.$store.state.assessment.assessmentStatus === AssessmentStatus.EXPIRED;
  }

  async testSound() {
    if (!this.audioPlaying) {
      const audio = new Audio(this.audioTestFile);
      this.audioPlaying = true;
      audio.addEventListener("ended", () => {
        this.audioPlaying = false;
      });

      await audio.play();
    }
  }

  async navigateToNextScreen(lang: string) {
    const subtitles = lang === "fr" ? "on" : "off";

    await this.getAssessmentSchema(lang);

    await this.$router.push({
      path: "identification",
      query: { lang, subtitles },
    });
  }

  async created(): Promise<void> {
    if (this.$route.query.t) {
      this.setAssessmentToken(this.$route.query.t as string);
      await this.getAssessmentStatusSummary();

      if (this.$store.state.assessment.assessmentStatusSummary?.isStarted ?? false) {
        this.isResuming = true;
      }

      if (this.$store.state.assessment.assessmentStatusSummary?.logo) {
        this.logoUrl = this.$store.state.assessment.assessmentStatusSummary.logo;
      } else {
        this.logoUrl = "/assets/images/Opening-page-logo.png";
      }
    } else {
      this.logoUrl = "/assets/images/Opening-page-logo.png";
    }
  }
}
