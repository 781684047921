import { render, staticRenderFns } from "./SearchReport.vue?vue&type=template&id=2c0fcdc0&scoped=true"
import script from "./SearchReport.vue?vue&type=script&lang=ts"
export * from "./SearchReport.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c0fcdc0",
  null
  
)

export default component.exports