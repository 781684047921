
import { TypedVue } from "@/store/types";
import { Component, Provide } from "vue-property-decorator";
import { ProjectReportData, Resource, V2Project } from "@/store/admin/adminTypes";
import { Action } from "vuex-class";
import { Actions } from "@/store/admin/adminActions";

import TeamEffectivenessReport from "@/components/project-reports/TeamEffectivenessReport.vue";
import VueI18n from "vue-i18n";

const namespace = "admin";

@Component({
  components: {
    TeamEffectivenessReport,
  },
})
export default class ProjectReport extends TypedVue {
  public report: ProjectReportData | null = null;
  public project: V2Project | null = null;
  public resource: Resource | null = null;

  @Action(Actions.GET_PROJECT_REPORT_DATA, { namespace })
  getProjectReportData!: (reportId: string) => Promise<ProjectReportData>;

  @Action(Actions.GET_PROJECT_BY_ID, { namespace })
  getProjectById!: (projectId: string) => Promise<V2Project>;

  @Action(Actions.GET_LITERALS, { namespace })
  getLiterals!: ({ module, version }: { module: string; version: string }) => Promise<Resource>;

  @Provide("$i18n") i18n = new VueI18n({
    locale: "en",
  });

  get loading() {
    return this.report === null || this.project === null || this.resource === null;
  }

  get reportId() {
    return this.$route.params.id;
  }

  async mounted() {
    this.report = await this.getProjectReportData(this.reportId);
    this.project = await this.getProjectById(this.report.projectId);

    this.resource = await this.getLiterals({ module: `project-report:team-effectiveness`, version: this.report.version.split(".")[0] });
    this.i18n.setLocaleMessage("en", { ...this.$i18n.getLocaleMessage("en"), ...this.resource.dictionary });
  }
}
