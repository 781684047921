/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AssessmentCreateResponse */
export interface AssessmentCreateResponse {
  /** Token */
  token: string;
}

/** AssessmentDetailUpdate */
export interface AssessmentDetailUpdate {
  /** Assessmentid */
  assessmentId: string;
  /** Candidatename */
  candidateName: string;
  /** Candidateemail */
  candidateEmail: string;
}

/** AssessmentFeedbackData */
export interface AssessmentFeedbackData {
  /** Text */
  text: string;
  /** Assessmentid */
  assessmentId: string;
}

/** AssessmentList */
export interface AssessmentList {
  /**
   * Totalresults
   * @default 0
   */
  totalResults?: number;
  /** Results */
  results: SchemasV2InternalAssessmentsAssessment[];
}

/** AssessmentRegen */
export interface AssessmentRegen {
  /** Assessmentid */
  assessmentId: string;
}

/** AssessmentShortResponse */
export interface AssessmentShortResponse {
  /** Assessments */
  assessments: string[][];
}

/**
 * AssessmentState
 * An enumeration.
 */
export enum AssessmentState {
  Incomplete = "incomplete",
  InProgress = "in-progress",
  Complete = "complete",
  ScoringComplete = "scoring_complete",
  ReportGenerated = "report-generated",
}

/** AssessmentStatusResponse */
export interface AssessmentStatusResponse {
  /** Assessmentid */
  assessmentId: string;
  /** Candidatename */
  candidateName: string;
  /** Candidateemail */
  candidateEmail?: string;
  /** Candidatelinkedin */
  candidateLinkedIn?: string;
  /** Hiringcompany */
  hiringCompany?: string;
  /** Agreedtotermsandconditions */
  agreedToTermsAndConditions?: boolean;
  /** Isstarted */
  isStarted: boolean;
  /** Logo */
  logo?: string;
}

/** AssessmentToken */
export interface AssessmentToken {
  /** Assessmentid */
  assessmentId: string;
  /** Jotformid */
  jotformId: string;
  /** Organization */
  organization: string;
  /** Project */
  project: string;
  /** Projectname */
  projectName: string;
  /** Candidatename */
  candidateName: string;
  /** Candidateemail */
  candidateEmail: string;
  /** Hiringcompany */
  hiringCompany: string;
  /** Projecttype */
  projectType?: string;
  /** Isdeleted */
  isDeleted?: boolean;
  /** Iscomplete */
  isComplete: boolean;
  /** Reportgenerated */
  reportGenerated: boolean;
  /** Attempts */
  attempts: number;
  /**
   * Expirationdate
   * @format date-time
   */
  expirationDate?: string;
  /**
   * Datecreated
   * @format date-time
   */
  dateCreated?: string;
  /**
   * Datecompleted
   * @format date-time
   */
  dateCompleted?: string;
}

/** AssessmentTokenResponse */
export interface AssessmentTokenResponse {
  /** Count */
  count: number;
  /** Assessments */
  assessments: AssessmentToken[];
}

/** AssetCreate */
export interface AssetCreate {
  /** Url */
  url: string;
}

/** BenchmarkList */
export interface BenchmarkList {
  /**
   * Totalresults
   * @default 0
   */
  totalResults?: number;
  /** Results */
  results: SchemasV2InternalBenchmarksBenchmark[];
}

/** BenchmarkResponse */
export interface BenchmarkResponse {
  /** Benchmarks */
  benchmarks: SchemasBenchmarkBenchmark[];
}

/** Body_bulk_create_assessment_api_admin_assessments_bulk_post */
export interface BodyBulkCreateAssessmentApiAdminAssessmentsBulkPost {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** Body_receive_form_data_api_admin_internal_webhooks_forms_post */
export interface BodyReceiveFormDataApiAdminInternalWebhooksFormsPost {
  /**
   * Formid
   * @default ""
   */
  formID?: string;
  /**
   * Submissionid
   * @default ""
   */
  submissionID?: string;
}

/** Body_receive_form_data_api_external_webhooks_forms_post */
export interface BodyReceiveFormDataApiExternalWebhooksFormsPost {
  /**
   * Formid
   * @default ""
   */
  formID?: string;
  /**
   * Submissionid
   * @default ""
   */
  submissionID?: string;
}

/** Body_upload_asset_api_admin_assets_post */
export interface BodyUploadAssetApiAdminAssetsPost {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** Body_upload_asset_api_internal_assets_post */
export interface BodyUploadAssetApiInternalAssetsPost {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** Body_upload_org_chart_api_admin_chart_post */
export interface BodyUploadOrgChartApiAdminChartPost {
  /** Project Id */
  project_id: string;
  /** Org Chart Title */
  org_chart_title: string;
  /**
   * File
   * @format binary
   */
  file: File;
}

/** Body_upload_org_chart_api_internal_projects__project_id__org_chart_post */
export interface BodyUploadOrgChartApiInternalProjectsProjectIdOrgChartPost {
  /** Org Chart Title */
  org_chart_title: string;
  /**
   * File
   * @format binary
   */
  file: File;
}

/** BulkGenerateResponse */
export interface BulkGenerateResponse {
  /** Affectedids */
  affectedIds: string[];
}

/** BulkModifyResponse */
export interface BulkModifyResponse {
  /** Affectedids */
  affectedIds: string[];
}

/** CalculateBenchmark */
export interface CalculateBenchmark {
  /**
   * Competencyscores
   * @default {}
   */
  competencyScores?: Record<string, number>;
  /**
   * Traitscores
   * @default {}
   */
  traitScores?: Record<string, number>;
}

/** ChartUpdate */
export interface ChartUpdate {
  /** Name */
  name: string;
  /** Organizationschema */
  organizationSchema?: string[][];
  /** Organizationnodes */
  organizationNodes: Record<string, string>[];
  /** Orgcharttitle */
  orgChartTitle?: string;
}

/** Competencies */
export interface Competencies {
  actionOriented: SchemasV2InternalAssessmentsCompetency;
  adaptable: SchemasV2InternalAssessmentsCompetency;
  dedicated: SchemasV2InternalAssessmentsCompetency;
  influential: SchemasV2InternalAssessmentsCompetency;
  insightful: SchemasV2InternalAssessmentsCompetency;
  peopleOriented: SchemasV2InternalAssessmentsCompetency;
  principled: SchemasV2InternalAssessmentsCompetency;
}

/** CompetenciesCamel */
export interface CompetenciesCamel {
  actionOriented: SchemasV2InternalAssessmentsCompetencyCamel;
  adaptable: SchemasV2InternalAssessmentsCompetencyCamel;
  dedicated: SchemasV2InternalAssessmentsCompetencyCamel;
  influential: SchemasV2InternalAssessmentsCompetencyCamel;
  insightful: SchemasV2InternalAssessmentsCompetencyCamel;
  peopleOriented: SchemasV2InternalAssessmentsCompetencyCamel;
  principled: SchemasV2InternalAssessmentsCompetencyCamel;
}

/** CreateReport */
export interface CreateReport {
  /** Name */
  name: string;
  /** Description */
  description?: string;
  /** Reportversion */
  reportVersion: string;
  /** Reporttype */
  reportType: string;
  /** Scoringversion */
  scoringVersion: string;
  /** Assessmentids */
  assessmentIds: string[];
  /**
   * Data
   * @default {}
   */
  data?: object;
}

/** Diversity */
export interface Diversity {
  /** Isdiverse */
  isDiverse: boolean;
  /** Attributes */
  attributes: string[];
  /** Consent */
  consent?: boolean;
}

/** DiversityAggregates */
export interface DiversityAggregates {
  /**
   * Diverse
   * @default 0
   */
  diverse?: number;
  /**
   * Marginalizedgender
   * @default 0
   */
  marginalizedGender?: number;
  /**
   * Indigenous
   * @default 0
   */
  indigenous?: number;
  /**
   * Visibleminority
   * @default 0
   */
  visibleMinority?: number;
  /**
   * Disabled
   * @default 0
   */
  disabled?: number;
  /**
   * Consenttoshare
   * @default 0
   */
  consentToShare?: number;
}

/** DiversityCamel */
export interface DiversityCamel {
  /** Isdiverse */
  isDiverse: boolean;
  /** Attributes */
  attributes: string[];
}

/** ElraReportDataResponse */
export interface ElraReportDataResponse {
  /** Type */
  type: string;
  /** Shortid */
  shortId: string;
  /** Readiness */
  readiness: string;
  /** Answersunchanged */
  answersUnchanged: boolean;
  /** Scoringversion */
  scoringVersion: string;
  /** Scoring */
  scoring: object;
  diversity: ReportDataDiversity;
  competencies: ReportDataCompetencies;
  comment?: ReportDataComment;
}

/** GenerateBenchmarkResponse */
export interface GenerateBenchmarkResponse {
  /**
   * Competencyscores
   * @default {}
   */
  competencyScores?: Record<string, number>;
  /**
   * Traitscores
   * @default {}
   */
  traitScores?: Record<string, number>;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** IDsList */
export interface IDsList {
  /** Ids */
  ids: string[];
  /** Scoringversion */
  scoringVersion: string;
}

/**
 * Language
 * An enumeration.
 */
export enum Language {
  En = "en",
  Fr = "fr",
}

/** Literal */
export interface Literal {
  /**
   * Datecreated
   * @format date-time
   */
  dateCreated: string;
  /** Language */
  language: string;
  /** Module */
  module: string;
  /** Version */
  version: string;
  /**
   * Dictionary
   * @default {}
   */
  dictionary?: Record<string, string>;
}

/** LiteralResponse */
export interface LiteralResponse {
  /**
   * Datecreated
   * @format date-time
   */
  dateCreated: string;
  /** Language */
  language: string;
  /** Module */
  module: string;
  /** Version */
  version: string;
  /**
   * Dictionary
   * @default {}
   */
  dictionary?: Record<string, string>;
}

/** OrganizationList */
export interface OrganizationList {
  /** Organizations */
  organizations: SchemasOrganizationOrganization[];
}

/** ProjectAggregates */
export interface ProjectAggregates {
  /**
   * Assessmentssent
   * @default 0
   */
  assessmentsSent?: number;
  /**
   * Assessmentscomplete
   * @default 0
   */
  assessmentsComplete?: number;
  /**
   * Candidatereports
   * @default 0
   */
  candidateReports?: number;
  readiness: ReadinessAggregates;
  diversity: DiversityAggregates;
}

/** ProjectAssessment */
export interface ProjectAssessment {
  /** Identifier */
  identifier: string;
  /**
   * Datecreated
   * @format date-time
   */
  dateCreated: string;
  /**
   * Datecompleted
   * @format date-time
   */
  dateCompleted?: string;
  /** State */
  state: string;
  /** Reportid */
  reportId?: string;
  candidate: ProjectCandidate;
}

/** ProjectCandidate */
export interface ProjectCandidate {
  /** Name */
  name: string;
}

/** ProjectComment */
export interface ProjectComment {
  /** Title */
  title: string;
  /** Body */
  body: string;
}

/** ProjectCreate */
export interface ProjectCreate {
  /** Name */
  name: string;
  /** Projecttype */
  projectType?: string;
  /** Selectedorganization */
  selectedOrganization?: string;
  /** Neworganization */
  newOrganization?: string;
  /** Logo */
  logo?: string;
  /** Features */
  features?: string[];
  /** Rootbenchmarkid */
  rootBenchmarkId: string;
}

/** ProjectOrgChart */
export interface ProjectOrgChart {
  /** Projectid */
  projectId: string;
  /** Ishiringincluded */
  isHiringIncluded: boolean;
  /** Parentofhiringnode */
  parentOfHiringNode?: number;
  /** Orgchart */
  orgChart: object[];
  /** Candidates */
  candidates: object[];
  /** Organizationcharttitle */
  organizationChartTitle?: string;
}

/** ProjectOrgChartResponse */
export interface ProjectOrgChartResponse {
  /** Projectid */
  projectId: string;
  /** Ishiringincluded */
  isHiringIncluded: boolean;
  /** Parentofhiringnode */
  parentOfHiringNode?: number;
  /** Orgchart */
  orgChart: object[];
  /** Candidates */
  candidates: object[];
  /** Organizationcharttitle */
  organizationChartTitle?: string;
}

/** ProjectOrganizationCreate */
export interface ProjectOrganizationCreate {
  /** Name */
  name: string;
  /** Projecttype */
  projectType?: string;
  /** Selectedorganization */
  selectedOrganization?: string;
  /** Neworganization */
  newOrganization?: string;
  /** Logo */
  logo?: string;
  /** Features */
  features?: string[];
}

/** ReadinessAggregates */
export interface ReadinessAggregates {
  /**
   * Balanced
   * @default 0
   */
  balanced?: number;
  /**
   * Niche
   * @default 0
   */
  niche?: number;
  /**
   * Contextual
   * @default 0
   */
  contextual?: number;
  /**
   * Lowalignment
   * @default 0
   */
  lowAlignment?: number;
  /**
   * Moderatealignment
   * @default 0
   */
  moderateAlignment?: number;
  /**
   * Moderatelyhighalignment
   * @default 0
   */
  moderatelyHighAlignment?: number;
  /**
   * Highalignment
   * @default 0
   */
  highAlignment?: number;
}

/** ReportDataComment */
export interface ReportDataComment {
  /** Title */
  title: string;
  /** Body */
  body: string;
}

/** ReportDataCompetencies */
export interface ReportDataCompetencies {
  actionOriented: SchemasReportCompetency;
  adaptable: SchemasReportCompetency;
  dedicated: SchemasReportCompetency;
  influential: SchemasReportCompetency;
  insightful: SchemasReportCompetency;
  peopleOriented: SchemasReportCompetency;
  principled: SchemasReportCompetency;
}

/** ReportDataCompetenciesCamel */
export interface ReportDataCompetenciesCamel {
  actionOriented: SchemasReportCompetencyCamel;
  adaptable: SchemasReportCompetencyCamel;
  dedicated: SchemasReportCompetencyCamel;
  influential: SchemasReportCompetencyCamel;
  insightful: SchemasReportCompetencyCamel;
  peopleOriented: SchemasReportCompetencyCamel;
  principled: SchemasReportCompetencyCamel;
}

/** ReportDataDiversity */
export interface ReportDataDiversity {
  /** Isdiverse */
  isDiverse: boolean;
  /** Attributes */
  attributes: string[];
  /** Consent */
  consent?: boolean;
}

/** ReportDataDiversityCamel */
export interface ReportDataDiversityCamel {
  /** Isdiverse */
  isDiverse: boolean;
  /** Attributes */
  attributes: string[];
}

/** ReportPreview */
export interface ReportPreview {
  /** Type */
  type: string;
  /** Shortid */
  shortId: string;
  /** Readiness */
  readiness: string;
  /** Answersunchanged */
  answersUnchanged: boolean;
  /** Scoringversion */
  scoringVersion: string;
  /** Scoring */
  scoring: object;
  diversity: Diversity;
  competencies: Competencies;
}

/** ReportRenderData */
export interface ReportRenderData {
  /** Version */
  version: string;
  /** Type */
  type: string;
  /** Readiness */
  readiness: string;
  /** Data */
  data: object;
  /** Projectid */
  projectId: string;
}

/** Scoring */
export interface Scoring {
  /** Scoringversion */
  scoringVersion: string;
  /** Readiness */
  readiness: string;
  /** Competencies */
  competencies: Record<string, number>;
  /** Competencyscores */
  competencyScores: Record<string, number>;
  /** Traitstatuses */
  traitStatuses: Record<string, number>;
  /** Traitscores */
  traitScores: Record<string, number>;
}

/** StatusResponse */
export interface StatusResponse {
  /** Readinesscounts */
  readinessCounts: Record<string, number>;
  /** Diversityindex */
  diversityIndex: number;
  /** Assessmentssent */
  assessmentsSent: number;
  /** Assessmentscompleted */
  assessmentsCompleted: number;
  /** Marginalizedgendercount */
  marginalizedGenderCount: number;
  /** Indigenouscount */
  indigenousCount: number;
  /** Visibleminoritycount */
  visibleMinorityCount: number;
  /** Disabledcount */
  disabledCount: number;
  /** Shareddiversity */
  sharedDiversity: number;
}

/** Template */
export interface Template {
  /** Identifier */
  identifier: string;
  /** Name */
  name: string;
  /** Template */
  template: string;
  /**
   * Datecreated
   * @format date-time
   */
  dateCreated: string;
  /** Originid */
  originId?: string;
  /** Iscustom */
  isCustom: boolean;
}

/** TemplateCreate */
export interface TemplateCreate {
  /** Name */
  name: string;
  /** Originid */
  originId?: string;
  /** Template */
  template: string;
  /** Iscustom */
  isCustom: boolean;
}

/** TemplateListResponse */
export interface TemplateListResponse {
  /** Templates */
  templates: TemplateResponse[];
}

/** TemplatePost */
export interface TemplatePost {
  /** Name */
  name: string;
  /** Originid */
  originId?: string;
  /** Template */
  template: string;
  /** Iscustom */
  isCustom: boolean;
}

/** TemplateResponse */
export interface TemplateResponse {
  /** Id */
  id: string;
  /** Name */
  name: string;
  /** Template */
  template: string;
  /**
   * Datecreated
   * @format date-time
   */
  dateCreated: string;
  /** Originid */
  originId?: string;
  /** Iscustom */
  isCustom: boolean;
}

/** TokenResponse */
export interface TokenResponse {
  /** Token */
  token: string;
}

/** UpdateReport */
export interface UpdateReport {
  /** Name */
  name: string;
  /** Description */
  description?: string;
  /** Assessmentids */
  assessmentIds: string[];
  /** Data */
  data: object;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: string[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

/** AssessmentCreate */
export interface SchemasAssessmentAssessmentCreate {
  /** Candidatename */
  candidateName: string;
  /** Candidateemail */
  candidateEmail: string;
  /** Organization */
  organization: string;
  /** Project */
  project: string;
  /** Projecttype */
  projectType: string;
  /**
   * Language
   * @default "en"
   */
  language?: string;
  /** Sendemail */
  sendEmail?: boolean;
}

/** AssessmentMigrate */
export interface SchemasAssessmentAssessmentMigrate {
  /** Assessmentids */
  assessmentIds: string[];
  /** Projectid */
  projectId: string;
}

/** AssessmentResponse */
export interface SchemasAssessmentAssessmentResponse {
  /** Assessmentid */
  assessmentId: string;
  /**
   * Datecreated
   * @format date-time
   */
  dateCreated: string;
  /**
   * Datecompleted
   * @format date-time
   */
  dateCompleted?: string;
  /**
   * Lastattemptdate
   * @format date-time
   */
  lastAttemptDate?: string;
  /** Attempts */
  attempts: number;
  /** Candidatename */
  candidateName: string;
  /** Candidateemail */
  candidateEmail?: string;
  /** Hiringcompany */
  hiringCompany?: string;
  /**
   * Questionresponses
   * @default {}
   */
  questionResponses?: Record<string, string[]>;
  /** Totaltestduration */
  totalTestDuration: number;
  /** Agreedtotermsandconditions */
  agreedToTermsAndConditions?: boolean;
  /** Iscomplete */
  isComplete: boolean;
  /**
   * Projectfeatures
   * @default []
   */
  projectFeatures?: string[];
}

/** AssessmentUpdate */
export interface SchemasAssessmentAssessmentUpdate {
  /** Candidatename */
  candidateName?: string;
  /** Candidatelinkedin */
  candidateLinkedIn?: string;
  /** Candidatephone */
  candidatePhone?: string;
  /** Candidateemail */
  candidateEmail?: string;
  /** Hiringcompany */
  hiringCompany?: string;
  /** Questionresponses */
  questionResponses?: Record<string, string[]>;
  /** Agreedtotermsandconditions */
  agreedToTermsAndConditions?: boolean;
  /** Iscomplete */
  isComplete?: boolean;
  /** Language */
  language?: string;
  /** Organization */
  organization?: string;
  /** Project */
  project?: string;
}

/** Benchmark */
export interface SchemasBenchmarkBenchmark {
  /** Benchmarkid */
  benchmarkId: string;
  /** Name */
  name: string;
  /** Description */
  description?: string;
  /** Organization */
  organization?: string[];
  /** Colour */
  colour: string;
  /** Alwaysshow */
  alwaysShow: boolean;
  /**
   * Competencyscores
   * @default {}
   */
  competencyScores?: Record<string, number>;
  /**
   * Traitscores
   * @default {}
   */
  traitScores?: Record<string, number>;
  /** Traitranges */
  traitRanges?: Record<string, number[]>;
  /** Traitcutoffs */
  traitCutoffs?: Record<string, Record<string, number>>;
  /**
   * Assessments
   * @default []
   */
  assessments?: string[];
  /**
   * Isadmin
   * @default false
   */
  isAdmin?: boolean;
}

/** CreateBenchmark */
export interface SchemasBenchmarkCreateBenchmark {
  /** Name */
  name: string;
  /** Description */
  description?: string;
  /** Organization */
  organization?: string[];
  /** Colour */
  colour: string;
  /**
   * Competencyscores
   * @default {}
   */
  competencyScores?: Record<string, number>;
  /**
   * Traitscores
   * @default {}
   */
  traitScores?: Record<string, number>;
  /**
   * Assessments
   * @default []
   */
  assessments?: string[];
  /**
   * Isadmin
   * @default false
   */
  isAdmin?: boolean;
}

/** Assessment */
export interface SchemasCandidateAssessment {
  /** Assessmentid */
  assessmentId: string;
  /** Project */
  project: string;
  /** Candidatename */
  candidateName: string;
  /** Readinessscore */
  readinessScore?: string;
  /**
   * Competencies
   * @default {}
   */
  competencies?: Record<string, number>;
  /**
   * Competencyscores
   * @default {}
   */
  competencyScores?: Record<string, number>;
  /** Organization */
  organization?: string;
  /**
   * Traitscores
   * @default {}
   */
  traitScores?: Record<string, number>;
  /** Iscomplete */
  isComplete: boolean;
}

/** Assessment */
export interface SchemasOrgAdminAssessment {
  /** Assessmentid */
  assessmentId: string;
  /** Project */
  project: string;
  /**
   * Datecreated
   * @format date-time
   */
  dateCreated: string;
  /**
   * Datecompleted
   * @format date-time
   */
  dateCompleted?: string;
  /**
   * Lastattemptdate
   * @format date-time
   */
  lastAttemptDate?: string;
  /** Candidatename */
  candidateName: string;
  /** Iscomplete */
  isComplete: boolean;
  /** Readinessscore */
  readinessScore?: string;
  /**
   * Competencies
   * @default {}
   */
  competencies?: Record<string, number>;
  /**
   * Competencyscores
   * @default {}
   */
  competencyScores?: Record<string, number>;
  /**
   * Traitscores
   * @default {}
   */
  traitScores?: Record<string, number>;
  /** Diverse */
  diverse?: boolean;
  /**
   * Pinned
   * @default false
   */
  pinned?: boolean;
  /** Hasreport */
  hasReport: boolean;
  /** Organization */
  organization?: string;
  /** Projecttype */
  projectType?: string;
  /** Internalresearchconsent */
  internalResearchConsent?: boolean;
  /** Aggregateresultsconsent */
  aggregateResultsConsent?: boolean;
  /** Identifiableresultsconsent */
  identifiableResultsConsent?: boolean;
}

/** AssessmentResponse */
export interface SchemasOrgAdminAssessmentResponse {
  /** Count */
  count: number;
  /** Assessments */
  assessments: SchemasOrgAdminAssessment[];
}

/** ProjectUpdate */
export interface SchemasOrgAdminProjectUpdate {
  /** Benchmarks */
  benchmarks?: string[];
  /** Theme */
  theme?: string;
}

/** Organization */
export interface SchemasOrganizationOrganization {
  /** Id */
  id: string;
  /** Displayname */
  displayName: string;
}

/** Project */
export interface SchemasProjectProject {
  /** Projectid */
  projectId: string;
  /** Name */
  name: string;
  /** Projecttype */
  projectType?: string;
  /** Organization */
  organization?: string;
  /** Organizationschema */
  organizationSchema?: string[][];
  /** Organizationnodes */
  organizationNodes: Record<string, string>[];
  /** Isdeleted */
  isDeleted: boolean;
  /**
   * Datecreated
   * @format date-time
   */
  dateCreated: string;
  /** Orgchartid */
  orgChartId?: string;
  /** Theme */
  theme: string;
  /** Benchmarks */
  benchmarks: string[];
  /** Logo */
  logo?: string;
  comment?: ProjectComment;
  /** Features */
  features: string[];
  /** Rootbenchmarkid */
  rootBenchmarkId: string;
}

/** ProjectList */
export interface SchemasProjectProjectList {
  /** Projects */
  projects: SchemasProjectProject[];
}

/** ProjectUpdate */
export interface SchemasProjectProjectUpdate {
  /** Name */
  name: string;
  /** Description */
  description: string;
  /** Logo */
  logo?: string;
}

/** Competency */
export interface SchemasReportCompetency {
  /** Score */
  score: number;
  /** Category */
  category: number;
  /** Level */
  level: number;
  /** Traits */
  traits: Record<string, boolean>;
  /** Optimalcontextinsights */
  optimalContextInsights: string[];
  /** Fragilecontextinsights */
  fragileContextInsights: string[];
  /** Hittraitbehaviours */
  hitTraitBehaviours: string[];
  /** Misstraitbehaviours */
  missTraitBehaviours: string[];
}

/** CompetencyCamel */
export interface SchemasReportCompetencyCamel {
  /** Score */
  score: number;
  /** Category */
  category: number;
  /** Level */
  level: number;
  /** Traits */
  traits: Record<string, boolean>;
  /** Optimalcontextinsights */
  optimalContextInsights: string[];
  /** Fragilecontextinsights */
  fragileContextInsights: string[];
  /** Hittraitbehaviours */
  hitTraitBehaviours: string[];
  /** Misstraitbehaviours */
  missTraitBehaviours: string[];
}

/** ElraReportData */
export interface SchemasReportElraReportData {
  diversity: ReportDataDiversityCamel;
  competencies: ReportDataCompetenciesCamel;
  /** Scoringversion */
  scoringVersion: string;
  /** Reportversion */
  reportVersion: string;
  comment?: ReportDataComment;
  /** Type */
  type: string;
}

/** ScoringVersions */
export interface SchemasReportScoringVersions {
  /** Versions */
  versions: string[];
}

/** UpdateUser */
export interface SchemasUsersUpdateUser {
  /** Acceptedtos */
  acceptedTos?: boolean;
}

/** AssessmentMigrate */
export interface SchemasV2ExternalAssessmentsAssessmentMigrate {
  /** Assessmentids */
  assessmentIds: string[];
  /** Projectid */
  projectId: string;
}

/** Assessment */
export interface SchemasV2InternalAssessmentsAssessment {
  /** Identifier */
  identifier: string;
  /** Orgid */
  orgId: string;
  /** Projectid */
  projectId: string;
  /** Candidateid */
  candidateId: string;
  /** Reportid */
  reportId?: string;
  /** Jotformid */
  jotformId?: string;
  /**
   * Datecreated
   * @format date-time
   */
  dateCreated: string;
  /**
   * Lastupdated
   * @format date-time
   */
  lastUpdated?: string;
  /**
   * Datestarted
   * @format date-time
   */
  dateStarted?: string;
  /**
   * Datecompleted
   * @format date-time
   */
  dateCompleted?: string;
  /**
   * Lastattemptdate
   * @format date-time
   */
  lastAttemptDate?: string;
  /**
   * Expirationdate
   * @format date-time
   */
  expirationDate?: string;
  /**
   * Reportviewed
   * @format date-time
   */
  reportViewed?: string;
  /** Attempts */
  attempts: number;
  /** Numberofresponses */
  numberOfResponses?: number;
  /** An enumeration. */
  state: AssessmentState;
  /** Language */
  language?: string;
  /** Company */
  company: string;
  /**
   * Iscopy
   * @default false
   */
  isCopy?: boolean;
  /** Isdeleted */
  isDeleted: boolean;
  project: SchemasV2InternalAssessmentsProject;
  candidate: SchemasV2InternalAssessmentsCandidate;
  report?: SchemasV2InternalAssessmentsReport;
  /**
   * Scores
   * @default []
   */
  scores?: Scoring[];
}

/** AssessmentCreate */
export interface SchemasV2InternalAssessmentsAssessmentCreate {
  /** Candidatename */
  candidateName: string;
  /** Candidateemail */
  candidateEmail: string;
  /** Organization */
  organization: string;
  /** Project */
  project: string;
  /** Projecttype */
  projectType: string;
  /** Sendemail */
  sendEmail?: boolean;
}

/** AssessmentUpdate */
export interface SchemasV2InternalAssessmentsAssessmentUpdate {
  /** Attempts */
  attempts: number;
}

/** Candidate */
export interface SchemasV2InternalAssessmentsCandidate {
  /** Name */
  name: string;
  /** Email */
  email: string;
}

/** Competency */
export interface SchemasV2InternalAssessmentsCompetency {
  /** Score */
  score: number;
  /** Category */
  category: number;
  /** Level */
  level: number;
  /** Traits */
  traits: Record<string, boolean>;
  /** Optimalcontextinsights */
  optimalContextInsights: string[];
  /** Fragilecontextinsights */
  fragileContextInsights: string[];
  /** Hittraitbehaviours */
  hitTraitBehaviours: string[];
  /** Misstraitbehaviours */
  missTraitBehaviours: string[];
}

/** CompetencyCamel */
export interface SchemasV2InternalAssessmentsCompetencyCamel {
  /** Score */
  score: number;
  /** Category */
  category: number;
  /** Level */
  level: number;
  /** Traits */
  traits: Record<string, boolean>;
  /** Optimalcontextinsights */
  optimalContextInsights: string[];
  /** Fragilecontextinsights */
  fragileContextInsights: string[];
  /** Hittraitbehaviours */
  hitTraitBehaviours: string[];
  /** Misstraitbehaviours */
  missTraitBehaviours: string[];
}

/** ElraReportData */
export interface SchemasV2InternalAssessmentsElraReportData {
  diversity: DiversityCamel;
  competencies: CompetenciesCamel;
  /** Scoringversion */
  scoringVersion: string;
  /** Reportversion */
  reportVersion: string;
}

/** Project */
export interface SchemasV2InternalAssessmentsProject {
  /** Isdeleted */
  isDeleted: boolean;
  /** Identifier */
  identifier: string;
  /** Name */
  name: string;
  /** Description */
  description?: string;
  /** Rootbenchmarkid */
  rootBenchmarkId: string;
}

/** Report */
export interface SchemasV2InternalAssessmentsReport {
  /** Identifier */
  identifier: string;
  /**
   * Datecreated
   * @format date-time
   */
  dateCreated: string;
  /** Type */
  type?: string;
  /** Version */
  version?: string;
}

/** ScoringVersions */
export interface SchemasV2InternalAssessmentsScoringVersions {
  /** Versions */
  versions: string[];
}

/** Benchmark */
export interface SchemasV2InternalBenchmarksBenchmark {
  /** Identifier */
  identifier: string;
  /** Name */
  name: string;
  /** Orgid */
  orgId?: string[];
  /** Colour */
  colour: string;
  /** Default */
  default: boolean;
  /**
   * Isadmin
   * @default false
   */
  isAdmin?: boolean;
  /**
   * Isroot
   * @default false
   */
  isRoot?: boolean;
  /**
   * Competencyscores
   * @default {}
   */
  competencyScores?: Record<string, number>;
  /**
   * Traitscores
   * @default {}
   */
  traitScores?: Record<string, number>;
  /** Traitranges */
  traitRanges?: Record<string, number[]>;
}

/** CreateBenchmark */
export interface SchemasV2InternalBenchmarksCreateBenchmark {
  /** Name */
  name: string;
  /** Organization */
  organization?: string[];
  /** Colour */
  colour: string;
  /**
   * Competencyscores
   * @default {}
   */
  competencyScores?: Record<string, number>;
  /**
   * Traitscores
   * @default {}
   */
  traitScores?: Record<string, number>;
  /**
   * Isadmin
   * @default false
   */
  isAdmin?: boolean;
}

/** Organization */
export interface SchemasV2InternalOrganizationsOrganization {
  /** Id */
  id: string;
  /** Displayname */
  displayName: string;
}

/** Project */
export interface SchemasV2InternalProjectsProject {
  /** Identifier */
  identifier: string;
  /**
   * Datecreated
   * @format date-time
   */
  dateCreated: string;
  /**
   * Lastupdated
   * @format date-time
   */
  lastUpdated?: string;
  /** Isdeleted */
  isDeleted: boolean;
  /** Orgid */
  orgId: string;
  /** Orgchartid */
  orgChartId?: string;
  /**
   * Assessmentids
   * @default []
   */
  assessmentIds?: string[];
  /**
   * Reports
   * @default []
   */
  reports?: SchemasV2InternalProjectsReport[];
  /**
   * Visiblebenchmarks
   * @default []
   */
  visibleBenchmarks?: string[];
  /** Rootbenchmarkid */
  rootBenchmarkId: string;
  /** Name */
  name: string;
  /** Description */
  description?: string;
  /** Theme */
  theme: string;
  /** Logo */
  logo?: string;
  /**
   * Features
   * @default []
   */
  features?: string[];
}

/** ProjectList */
export interface SchemasV2InternalProjectsProjectList {
  /**
   * Totalresults
   * @default 0
   */
  totalResults?: number;
  /** Results */
  results: SchemasV2InternalProjectsProject[];
}

/** ProjectUpdate */
export interface SchemasV2InternalProjectsProjectUpdate {
  /** Name */
  name: string;
  /** Description */
  description: string;
  /** Logo */
  logo?: string;
  /** Assessmentids */
  assessmentIds: string[];
  /** Visiblebenchmarks */
  visibleBenchmarks: string[];
}

/** Report */
export interface SchemasV2InternalProjectsReport {
  /** Identifier */
  identifier: string;
  /**
   * Datecreated
   * @format date-time
   */
  dateCreated: string;
  /**
   * Lastupdated
   * @format date-time
   */
  lastUpdated?: string;
  /** Type */
  type: string;
  /** Version */
  version: string;
  /** Scoringversion */
  scoringVersion: string;
  /** Name */
  name?: string;
  /** Description */
  description?: string;
}

/** ElraReport */
export interface SchemasV2InternalReportsElraReport {
  /** Identifier */
  identifier: string;
  /**
   * Datecreated
   * @format date-time
   */
  dateCreated: string;
  /**
   * Lastupdated
   * @format date-time
   */
  lastUpdated: string;
  /** Scoringversion */
  scoringVersion: string;
  /** Projectid */
  projectId: string;
  /** Name */
  name: string;
  /** Description */
  description?: string;
  /** Version */
  version: string;
  /** Type */
  type: string;
  /** Data */
  data: object;
  /** Readiness */
  readiness: string;
  /** Competencies */
  competencies: object;
  /** Competencyscores */
  competencyScores: object;
  /** Traitstatuses */
  traitStatuses: object;
  /** Traitscores */
  traitScores: object;
}

/** ProjectReport */
export interface SchemasV2InternalReportsProjectReport {
  /** Identifier */
  identifier: string;
  /**
   * Datecreated
   * @format date-time
   */
  dateCreated: string;
  /**
   * Lastupdated
   * @format date-time
   */
  lastUpdated: string;
  /** Scoringversion */
  scoringVersion: string;
  /** Projectid */
  projectId: string;
  /** Name */
  name: string;
  /** Description */
  description?: string;
  /** Version */
  version: string;
  /** Type */
  type: string;
  /** Data */
  data: object;
  /** Assessmentids */
  assessmentIds: string[];
}

/** Assessment */
export interface SchemasV2SystemAssessmentsAssessment {
  /** Identifier */
  identifier: string;
  /** Orgid */
  orgId: string;
  /** Projectid */
  projectId: string;
  /** Reportid */
  reportId?: string;
  /**
   * Datecreated
   * @format date-time
   */
  dateCreated: string;
  /**
   * Datecompleted
   * @format date-time
   */
  dateCompleted?: string;
  /**
   * Lastattemptdate
   * @format date-time
   */
  lastAttemptDate?: string;
  /**
   * Expirationdate
   * @format date-time
   */
  expirationDate?: string;
  /** Attempts */
  attempts: number;
  /** State */
  state: string;
  /** Company */
  company: string;
  /** Isdeleted */
  isDeleted: boolean;
  candidate: SchemasV2SystemAssessmentsCandidate;
  report?: SchemasV2SystemAssessmentsReport;
}

/** Candidate */
export interface SchemasV2SystemAssessmentsCandidate {
  /** Name */
  name: string;
  /** Email */
  email: string;
  /** Isdiverse */
  isDiverse?: boolean;
  /** Diversity */
  diversity?: object;
}

/** Report */
export interface SchemasV2SystemAssessmentsReport {
  /** Identifier */
  identifier: string;
  /**
   * Datecreated
   * @format date-time
   */
  dateCreated: string;
  /** Version */
  version?: string;
  /** Readiness */
  readiness?: string;
  /** Competencies */
  competencies?: Record<string, number>;
  /** Competencyscores */
  competencyScores?: Record<string, number>;
  /** Traitscores */
  traitScores?: Record<string, number>;
  /** Traitstatuses */
  traitStatuses?: Record<string, number>;
}

/** Benchmark */
export interface SchemasV2SystemBenchmarksBenchmark {
  /** Identifier */
  identifier: string;
  /** Name */
  name: string;
  /** Orgid */
  orgId?: string[];
  /** Colour */
  colour: string;
  /** Default */
  default: boolean;
  /**
   * Isadmin
   * @default false
   */
  isAdmin?: boolean;
  /**
   * Competencyscores
   * @default {}
   */
  competencyScores?: Record<string, number>;
  /**
   * Traitscores
   * @default {}
   */
  traitScores?: Record<string, number>;
  /** Traitranges */
  traitRanges?: Record<string, number[]>;
  /** Traitcutoffs */
  traitCutoffs?: Record<string, Record<string, number>>;
}

/** Project */
export interface SchemasV2SystemProjectsProject {
  /**
   * Datecreated
   * @format date-time
   */
  dateCreated: string;
  /**
   * Lastupdated
   * @format date-time
   */
  lastUpdated?: string;
  /** Identifier */
  identifier: string;
  /** Orgid */
  orgId: string;
  /** Orgchartid */
  orgChartId?: string;
  /**
   * Reports
   * @default []
   */
  reports?: SchemasV2SystemProjectsProjectReport[];
  /**
   * Assessments
   * @default []
   */
  assessments?: ProjectAssessment[];
  /** Name */
  name: string;
  /** Description */
  description?: string;
  /** Theme */
  theme: string;
  /** Logo */
  logo?: string;
  /**
   * Benchmarks
   * @default []
   */
  benchmarks?: SchemasV2SystemBenchmarksBenchmark[];
  /**
   * Features
   * @default []
   */
  features?: string[];
  /** Rootbenchmarkid */
  rootBenchmarkId: string;
}

/** ProjectReport */
export interface SchemasV2SystemProjectsProjectReport {
  /** Identifier */
  identifier: string;
  /**
   * Datecreated
   * @format date-time
   */
  dateCreated: string;
  /** Version */
  version: string;
  /** Type */
  type: string;
  /** Name */
  name: string;
  /** Description */
  description?: string;
}

/** ProjectUpdate */
export interface SchemasV2SystemProjectsProjectUpdate {
  /** Benchmarks */
  benchmarks?: string[];
  /** Theme */
  theme?: string;
}

/** ElraReport */
export interface SchemasV2SystemReportsElraReport {
  /** Identifier */
  identifier: string;
  /** Version */
  version: string;
  /** Type */
  type: string;
  /** Readiness */
  readiness: string;
  /** Data */
  data: object;
  /** Projectid */
  projectId: string;
}

/** ProjectReport */
export interface SchemasV2SystemReportsProjectReport {
  /** Identifier */
  identifier: string;
  /** Projectid */
  projectId: string;
  /** Name */
  name: string;
  /** Version */
  version: string;
  /** Type */
  type: string;
  /** Data */
  data: object;
}

/** UpdateUser */
export interface SchemasV2SystemUsersUpdateUser {
  /** Acceptedtos */
  acceptedTos?: boolean;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (securityData: SecurityDataType | null) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({ secure, path, type, query, format, body, ...params }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams = ((typeof secure === "boolean" ? secure : this.secure) && this.securityWorker && (await this.securityWorker(this.securityData))) || {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title System3 API
 * @version 0.1.0
 *
 * The API powering the System3 assesment platform
 */
export class S3PlatformApi<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  assessments = {
    /**
     * No description
     *
     * @tags Assessments
     * @name GetOwnAssessment
     * @summary Get Own Assessment
     * @request GET:/api/assessments/me
     * @secure
     */
    getOwnAssessment: (params: RequestParams = {}) =>
      this.request<SchemasAssessmentAssessmentResponse, any>({
        path: `/api/assessments/me`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assessments
     * @name UpdateOwnAssessment
     * @summary Update Own Assessment
     * @request PATCH:/api/assessments/me
     * @secure
     */
    updateOwnAssessment: (data: SchemasAssessmentAssessmentUpdate, params: RequestParams = {}) =>
      this.request<SchemasAssessmentAssessmentResponse, HTTPValidationError>({
        path: `/api/assessments/me`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assessments
     * @name GetOwnAssessmentStatus
     * @summary Get Own Assessment Status
     * @request GET:/api/assessments/me/status
     * @secure
     */
    getOwnAssessmentStatus: (params: RequestParams = {}) =>
      this.request<AssessmentStatusResponse, any>({
        path: `/api/assessments/me/status`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assessments
     * @name GetAssessmentSchema
     * @summary Get Assessment Schema
     * @request GET:/api/assessments/schema
     * @secure
     */
    getAssessmentSchema: (
      query?: {
        /** @default "en" */
        lang?: Language;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/api/assessments/schema`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  admin = {
    /**
     * No description
     *
     * @tags Reports Internal
     * @name BulkRegenerateReports
     * @summary Bulk Regenerate Reports
     * @request POST:/api/admin/internal/reports/generate
     * @secure
     */
    bulkRegenerateReports: (
      query: {
        /** Project */
        project: string;
        /** Scoring Version */
        scoring_version: string;
        /** Report Version */
        report_version: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BulkGenerateResponse, HTTPValidationError>({
        path: `/api/admin/internal/reports/generate`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports Internal
     * @name GetScoringVersions
     * @summary Get Scoring Versions
     * @request GET:/api/admin/internal/reports/scoring/versions
     * @secure
     */
    getScoringVersions: (params: RequestParams = {}) =>
      this.request<SchemasReportScoringVersions, any>({
        path: `/api/admin/internal/reports/scoring/versions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports Internal
     * @name GetAssessmentScoringVersions
     * @summary Get Assessment Scoring Versions
     * @request GET:/api/admin/internal/reports/{assessment_id}/scoring/versions
     * @secure
     */
    getAssessmentScoringVersions: (assessmentId: string, params: RequestParams = {}) =>
      this.request<SchemasReportScoringVersions, HTTPValidationError>({
        path: `/api/admin/internal/reports/${assessmentId}/scoring/versions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Benchmarks Internal
     * @name GetBenchmarks
     * @summary Get Benchmarks
     * @request GET:/api/admin/internal/benchmarks
     * @secure
     */
    getBenchmarks: (params: RequestParams = {}) =>
      this.request<BenchmarkResponse, any>({
        path: `/api/admin/internal/benchmarks`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Benchmarks Internal
     * @name CreateBenchmark
     * @summary Create Benchmark
     * @request POST:/api/admin/internal/benchmarks
     * @secure
     */
    createBenchmark: (data: SchemasBenchmarkCreateBenchmark, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/internal/benchmarks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Benchmarks Internal
     * @name GenerateBenchmarkSores
     * @summary Generate Benchmark Sores
     * @request POST:/api/admin/internal/benchmarks/generate
     * @secure
     */
    generateBenchmarkSores: (data: IDsList, params: RequestParams = {}) =>
      this.request<GenerateBenchmarkResponse, HTTPValidationError>({
        path: `/api/admin/internal/benchmarks/generate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Benchmarks Internal
     * @name DeleteBenchmark
     * @summary Delete Benchmark
     * @request DELETE:/api/admin/internal/benchmarks/{benchmark_id}
     * @secure
     */
    deleteBenchmark: (benchmarkId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/internal/benchmarks/${benchmarkId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Benchmarks Internal
     * @name UpdateBenchmark
     * @summary Update Benchmark
     * @request PATCH:/api/admin/internal/benchmarks/{benchmark_id}
     * @secure
     */
    updateBenchmark: (benchmarkId: string, data: SchemasBenchmarkCreateBenchmark, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/internal/benchmarks/${benchmarkId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Benchmarks Internal
     * @name GetProjectsWithBenchmark
     * @summary Get Projects With Benchmark
     * @request GET:/api/admin/internal/benchmarks/{benchmark_id}/projects
     * @secure
     */
    getProjectsWithBenchmark: (benchmarkId: string, params: RequestParams = {}) =>
      this.request<SchemasProjectProjectList, HTTPValidationError>({
        path: `/api/admin/internal/benchmarks/${benchmarkId}/projects`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects Internal
     * @name UpdateProject
     * @summary Update Project
     * @request PUT:/api/admin/internal/projects/{project_id}
     * @secure
     */
    updateProject: (projectId: string, data: SchemasProjectProjectUpdate, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/internal/projects/${projectId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects Internal
     * @name AddAssessmentToProject
     * @summary Add Assessment To Project
     * @request POST:/api/admin/internal/projects/{project_id}/assessments/{assessment_id}
     * @secure
     */
    addAssessmentToProject: (assessmentId: string, projectId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/internal/projects/${projectId}/assessments/${assessmentId}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects Internal
     * @name RemoveAssessmentFromProject
     * @summary Remove Assessment From Project
     * @request DELETE:/api/admin/internal/projects/{project_id}/assessments/{assessment_id}
     * @secure
     */
    removeAssessmentFromProject: (assessmentId: string, projectId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/internal/projects/${projectId}/assessments/${assessmentId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects Internal
     * @name AddBenchmarkToProject
     * @summary Add Benchmark To Project
     * @request POST:/api/admin/internal/projects/{project_id}/benchmarks/{benchmark_id}
     * @secure
     */
    addBenchmarkToProject: (projectId: string, benchmarkId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/internal/projects/${projectId}/benchmarks/${benchmarkId}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects Internal
     * @name ReomveBenchmarkFromProject
     * @summary Reomve Benchmark From Project
     * @request DELETE:/api/admin/internal/projects/{project_id}/benchmarks/{benchmark_id}
     * @secure
     */
    reomveBenchmarkFromProject: (projectId: string, benchmarkId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/internal/projects/${projectId}/benchmarks/${benchmarkId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assessments Internal
     * @name CloneAssessments
     * @summary Clone Assessments
     * @request POST:/api/admin/internal/assessments/clone
     * @secure
     */
    cloneAssessments: (
      data: SchemasAssessmentAssessmentMigrate,
      query?: {
        /**
         * Anonymize Names
         * @default false
         */
        anonymize_names?: boolean;
        /**
         * Anonymize Email
         * @default false
         */
        anonymize_email?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<BulkGenerateResponse, HTTPValidationError>({
        path: `/api/admin/internal/assessments/clone`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assessments Internal
     * @name MoveAssessments
     * @summary Move Assessments
     * @request POST:/api/admin/internal/assessments/move
     * @secure
     */
    moveAssessments: (data: SchemasAssessmentAssessmentMigrate, params: RequestParams = {}) =>
      this.request<BulkGenerateResponse, HTTPValidationError>({
        path: `/api/admin/internal/assessments/move`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Webhooks Internal
     * @name ReceiveFormData
     * @summary Receive Form Data
     * @request POST:/api/admin/internal/webhooks/forms
     */
    receiveFormData: (
      query: {
        /** Api Key */
        api_key: string;
      },
      data: BodyReceiveFormDataApiAdminInternalWebhooksFormsPost,
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/internal/webhooks/forms`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization Admin
     * @name GetStatus
     * @summary Get Status
     * @request GET:/api/admin/organization/status
     * @secure
     */
    getStatus: (
      query?: {
        /** Project */
        project?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<StatusResponse, HTTPValidationError>({
        path: `/api/admin/organization/status`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization Admin
     * @name GetAssessments
     * @summary Get Assessments
     * @request GET:/api/admin/organization/assessments
     * @secure
     */
    getAssessments: (
      query?: {
        /** Project */
        project?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SchemasOrgAdminAssessmentResponse, HTTPValidationError>({
        path: `/api/admin/organization/assessments`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization Admin
     * @name GetProjects
     * @summary Get Projects
     * @request GET:/api/admin/organization/projects
     * @secure
     */
    getProjects: (params: RequestParams = {}) =>
      this.request<SchemasProjectProjectList, any>({
        path: `/api/admin/organization/projects`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization Admin
     * @name GetBenchmarks2
     * @summary Get Benchmarks
     * @request GET:/api/admin/organization/benchmarks
     * @originalName getBenchmarks
     * @duplicate
     * @secure
     */
    getBenchmarks2: (params: RequestParams = {}) =>
      this.request<BenchmarkResponse, any>({
        path: `/api/admin/organization/benchmarks`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization Admin
     * @name GetProjectBenchmarks
     * @summary Get Project Benchmarks
     * @request GET:/api/admin/organization/project/{project_id}/benchmarks
     * @secure
     */
    getProjectBenchmarks: (projectId: string, params: RequestParams = {}) =>
      this.request<BenchmarkResponse, HTTPValidationError>({
        path: `/api/admin/organization/project/${projectId}/benchmarks`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization Admin
     * @name GetReportRenderData
     * @summary Get Report Render Data
     * @request GET:/api/admin/organization/report/{candidate_id}/render-data
     * @secure
     */
    getReportRenderData: (candidateId: string, params: RequestParams = {}) =>
      this.request<ReportRenderData, HTTPValidationError>({
        path: `/api/admin/organization/report/${candidateId}/render-data`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization Admin
     * @name GetOrgChart
     * @summary Get Org Chart
     * @request GET:/api/admin/organization/chart
     * @secure
     */
    getOrgChart: (
      query: {
        /** Project */
        project: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProjectOrgChartResponse, HTTPValidationError>({
        path: `/api/admin/organization/chart`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization Admin
     * @name UpdateProject2
     * @summary Update Project
     * @request PATCH:/api/admin/organization/project/{project_id}
     * @originalName updateProject
     * @duplicate
     * @secure
     */
    updateProject2: (projectId: string, data: SchemasOrgAdminProjectUpdate, params: RequestParams = {}) =>
      this.request<SchemasProjectProject, HTTPValidationError>({
        path: `/api/admin/organization/project/${projectId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidate
     * @name GetCandidateBenchmarks
     * @summary Get Candidate Benchmarks
     * @request GET:/api/admin/candidate/{assessment_id}/benchmarks
     * @secure
     */
    getCandidateBenchmarks: (assessmentId: string, params: RequestParams = {}) =>
      this.request<BenchmarkResponse, HTTPValidationError>({
        path: `/api/admin/candidate/${assessmentId}/benchmarks`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidate
     * @name GetCandidateAssessment
     * @summary Get Candidate Assessment
     * @request GET:/api/admin/candidate/{assessment_id}/assessment
     * @secure
     */
    getCandidateAssessment: (assessmentId: string, params: RequestParams = {}) =>
      this.request<SchemasCandidateAssessment, HTTPValidationError>({
        path: `/api/admin/candidate/${assessmentId}/assessment`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidate
     * @name GetCandidateProject
     * @summary Get Candidate Project
     * @request GET:/api/admin/candidate/{assessment_id}/project
     * @secure
     */
    getCandidateProject: (assessmentId: string, params: RequestParams = {}) =>
      this.request<SchemasProjectProject, HTTPValidationError>({
        path: `/api/admin/candidate/${assessmentId}/project`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UpdateUser
     * @summary Update User
     * @request PATCH:/api/admin/users/me
     * @secure
     */
    updateUser: (data: SchemasUsersUpdateUser, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/users/me`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetOrgsForUser
     * @summary Get Orgs For User
     * @request GET:/api/admin/users/{user_id}/organizations
     * @secure
     */
    getOrgsForUser: (userId: string, params: RequestParams = {}) =>
      this.request<OrganizationList, HTTPValidationError>({
        path: `/api/admin/users/${userId}/organizations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global Admin
     * @name GetAssessmentsInProject
     * @summary Get Assessments In Project
     * @request GET:/api/admin/assessments
     * @secure
     */
    getAssessmentsInProject: (
      query: {
        /** Project */
        project: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SchemasOrgAdminAssessmentResponse, HTTPValidationError>({
        path: `/api/admin/assessments`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global Admin
     * @name CreateAssessment
     * @summary Create Assessment
     * @request POST:/api/admin/assessments
     * @secure
     */
    createAssessment: (data: SchemasAssessmentAssessmentCreate, params: RequestParams = {}) =>
      this.request<AssessmentCreateResponse, HTTPValidationError>({
        path: `/api/admin/assessments`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global Admin
     * @name ResetRegen
     * @summary Reset Regen
     * @request POST:/api/admin/assessment-regen
     * @secure
     */
    resetRegen: (data: AssessmentRegen, params: RequestParams = {}) =>
      this.request<AssessmentCreateResponse, HTTPValidationError>({
        path: `/api/admin/assessment-regen`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global Admin
     * @name AssessmentReset
     * @summary Assessment Reset
     * @request POST:/api/admin/assessment-reset
     * @secure
     */
    assessmentReset: (data: AssessmentRegen, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/assessment-reset`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global Admin
     * @name AssessmentRecalculateScores
     * @summary Assessment Recalculate Scores
     * @request POST:/api/admin/assessments/{assessment_id}/recalculate
     * @secure
     */
    assessmentRecalculateScores: (assessmentId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/assessments/${assessmentId}/recalculate`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global Admin
     * @name GetAssessmentDump
     * @summary Get Assessment Dump
     * @request GET:/api/admin/assessment-dump
     * @secure
     */
    getAssessmentDump: (
      query: {
        /** Project */
        project: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/assessment-dump`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global Admin
     * @name UploadOrgChart
     * @summary Upload Org Chart
     * @request POST:/api/admin/chart
     * @secure
     */
    uploadOrgChart: (data: BodyUploadOrgChartApiAdminChartPost, params: RequestParams = {}) =>
      this.request<ChartUpdate, HTTPValidationError>({
        path: `/api/admin/chart`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global Admin
     * @name GetOrganizations
     * @summary Get Organizations
     * @request GET:/api/admin/organizations
     * @secure
     */
    getOrganizations: (params: RequestParams = {}) =>
      this.request<OrganizationList, any>({
        path: `/api/admin/organizations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global Admin
     * @name GetProjectsInOrg
     * @summary Get Projects In Org
     * @request GET:/api/admin/projects
     * @secure
     */
    getProjectsInOrg: (
      query?: {
        /** Organization */
        organization?: string;
        /** Assessment */
        assessment?: string;
        /**
         * Include Deleted
         * @default false
         */
        include_deleted?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<SchemasProjectProjectList, HTTPValidationError>({
        path: `/api/admin/projects`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global Admin
     * @name GetReportTemplateData
     * @summary Get Report Template Data
     * @request GET:/api/admin/report/{assessment_id}/template-data
     * @secure
     */
    getReportTemplateData: (
      assessmentId: string,
      query: {
        /** Scoring Version */
        scoring_version: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ElraReportDataResponse, HTTPValidationError>({
        path: `/api/admin/report/${assessmentId}/template-data`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global Admin
     * @name PublishReportData
     * @summary Publish Report Data
     * @request POST:/api/admin/report/{assessment_id}
     * @secure
     */
    publishReportData: (
      assessmentId: string,
      data: SchemasReportElraReportData,
      query?: {
        /**
         * Send Email
         * @default false
         */
        send_email?: boolean;
        /**
         * Beta
         * @default false
         */
        beta?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/report/${assessmentId}`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global Admin
     * @name SendReportEmail
     * @summary Send Report Email
     * @request POST:/api/admin/report/{assessment_id}/send-email
     * @secure
     */
    sendReportEmail: (assessmentId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/report/${assessmentId}/send-email`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global Admin
     * @name CreateProject
     * @summary Create Project
     * @request POST:/api/admin/projects/
     * @secure
     */
    createProject: (data: ProjectOrganizationCreate, params: RequestParams = {}) =>
      this.request<SchemasProjectProject, HTTPValidationError>({
        path: `/api/admin/projects/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global Admin
     * @name DeleteProject
     * @summary Delete Project
     * @request DELETE:/api/admin/projects/{project_id}
     * @secure
     */
    deleteProject: (projectId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/projects/${projectId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global Admin
     * @name GetAllAssessments
     * @summary Get All Assessments
     * @request GET:/api/admin/all-assessments
     * @secure
     */
    getAllAssessments: (
      query?: {
        /**
         * Page
         * @exclusiveMin 0
         * @default 1
         */
        page?: number;
        /**
         * Page Size
         * @exclusiveMin 0
         * @max 100
         * @default 10
         */
        page_size?: number;
        /**
         * Sort
         * @default "-date_created"
         */
        sort?: string;
        /**
         * Search
         * @default ""
         */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AssessmentTokenResponse, HTTPValidationError>({
        path: `/api/admin/all-assessments`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global Admin
     * @name EditAssessment
     * @summary Edit Assessment
     * @request PUT:/api/admin/assessment-edit
     * @secure
     */
    editAssessment: (data: AssessmentDetailUpdate, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/assessment-edit`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global Admin
     * @name SoftDeleteAssessment
     * @summary Soft Delete Assessment
     * @request DELETE:/api/admin/assessment-soft-delete/{assessment_id}
     * @secure
     */
    softDeleteAssessment: (assessmentId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/assessment-soft-delete/${assessmentId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global Admin
     * @name BulkCreateAssessment
     * @summary Bulk Create Assessment
     * @request POST:/api/admin/assessments/bulk
     * @secure
     */
    bulkCreateAssessment: (data: BodyBulkCreateAssessmentApiAdminAssessmentsBulkPost, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/assessments/bulk`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global Admin
     * @name GetAssessmentsShort
     * @summary Get Assessments Short
     * @request GET:/api/admin/assessments/short
     * @secure
     */
    getAssessmentsShort: (
      query?: {
        /**
         * Only Complete
         * @default false
         */
        only_complete?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<AssessmentShortResponse, HTTPValidationError>({
        path: `/api/admin/assessments/short`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global Admin
     * @name UploadAsset
     * @summary Upload Asset
     * @request POST:/api/admin/assets
     * @secure
     */
    uploadAsset: (data: BodyUploadAssetApiAdminAssetsPost, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/assets`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feedback
     * @name ReceiveFeedbackData
     * @summary Receive Feedback Data
     * @request POST:/api/admin/feedback
     * @secure
     */
    receiveFeedbackData: (data: AssessmentFeedbackData, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/feedback`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  ping = {
    /**
     * No description
     *
     * @tags Utilities
     * @name Ping
     * @summary Ping
     * @request GET:/api/ping/
     */
    ping: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/api/ping/`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  resources = {
    /**
     * No description
     *
     * @tags Resources
     * @name GetModuleLiteral
     * @summary Get Module Literal
     * @request GET:/api/resources/{module}
     */
    getModuleLiteral: (
      module: string,
      query?: {
        /** Version */
        version?: string;
        /**
         * Lang
         * @default "en"
         */
        lang?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<LiteralResponse, HTTPValidationError>({
        path: `/api/resources/${module}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  templates = {
    /**
     * No description
     *
     * @tags Templates
     * @name GetReportTemplate
     * @summary Get Report Template
     * @request GET:/api/templates/{name}
     */
    getReportTemplate: (name: string, params: RequestParams = {}) =>
      this.request<TemplateResponse, HTTPValidationError>({
        path: `/api/templates/${name}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Templates
     * @name ListReportTemplates
     * @summary List Report Templates
     * @request GET:/api/templates/
     */
    listReportTemplates: (
      query?: {
        /** Origin Id */
        origin_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TemplateListResponse, HTTPValidationError>({
        path: `/api/templates/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Templates
     * @name PostReportTemplate
     * @summary Post Report Template
     * @request POST:/api/templates/
     */
    postReportTemplate: (data: TemplatePost, params: RequestParams = {}) =>
      this.request<TemplateResponse, HTTPValidationError>({
        path: `/api/templates/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  internal = {
    /**
     * No description
     *
     * @tags Internal
     * @name GetManyProjects
     * @summary Get Many Projects
     * @request GET:/api/internal/projects
     * @secure
     */
    getManyProjects: (
      query?: {
        /**
         * Page
         * @exclusiveMin 0
         * @default 1
         */
        page?: number;
        /**
         * Page Size
         * @exclusiveMin 0
         * @default 10
         */
        page_size?: number;
        /**
         * Sort
         * @default "-date_created"
         */
        sort?: string;
        /** Org Id */
        org_id?: string;
        /** Assessment Id */
        assessment_id?: string;
        /** Benchmark Id */
        benchmark_id?: string;
        /**
         * Include Deleted
         * @default false
         */
        include_deleted?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<SchemasV2InternalProjectsProjectList, HTTPValidationError>({
        path: `/api/internal/projects`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name CreateProject
     * @summary Create Project
     * @request POST:/api/internal/projects/projects/
     * @secure
     */
    createProject: (data: ProjectCreate, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/internal/projects/projects/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name UpdateProject
     * @summary Update Project
     * @request PUT:/api/internal/projects/{project_id}
     * @secure
     */
    updateProject: (projectId: string, data: SchemasV2InternalProjectsProjectUpdate, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/internal/projects/${projectId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name DeleteProject
     * @summary Delete Project
     * @request DELETE:/api/internal/projects/projects/{project_id}
     * @secure
     */
    deleteProject: (projectId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/internal/projects/projects/${projectId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name CreateProjectReport
     * @summary Create Project Report
     * @request POST:/api/internal/projects/{project_id}/reports
     * @secure
     */
    createProjectReport: (projectId: string, data: CreateReport, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/internal/projects/${projectId}/reports`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name UploadOrgChart
     * @summary Upload Org Chart
     * @request POST:/api/internal/projects/{project_id}/org-chart
     * @secure
     */
    uploadOrgChart: (projectId: string, data: BodyUploadOrgChartApiInternalProjectsProjectIdOrgChartPost, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/internal/projects/${projectId}/org-chart`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name GetReport
     * @summary Get Report
     * @request GET:/api/internal/reports/{report_id}
     * @secure
     */
    getReport: (reportId: string, params: RequestParams = {}) =>
      this.request<SchemasV2InternalReportsProjectReport | SchemasV2InternalReportsElraReport | (SchemasV2InternalReportsProjectReport & SchemasV2InternalReportsElraReport), HTTPValidationError>({
        path: `/api/internal/reports/${reportId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name UpdateReport
     * @summary Update Report
     * @request PUT:/api/internal/reports/{report_id}
     * @secure
     */
    updateReport: (reportId: string, data: UpdateReport, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/internal/reports/${reportId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name DeleteReport
     * @summary Delete Report
     * @request DELETE:/api/internal/reports/{report_id}
     * @secure
     */
    deleteReport: (reportId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/internal/reports/${reportId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name SendReportEmail
     * @summary Send Report Email
     * @request POST:/api/internal/reports/{report_id}/email_candidate
     * @secure
     */
    sendReportEmail: (reportId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/internal/reports/${reportId}/email_candidate`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name GetManyAssessments
     * @summary Get Many Assessments
     * @request GET:/api/internal/assessments
     * @secure
     */
    getManyAssessments: (
      query?: {
        /**
         * Page
         * @exclusiveMin 0
         * @default 1
         */
        page?: number;
        /**
         * Page Size
         * @exclusiveMin 0
         * @default 10
         */
        page_size?: number;
        /**
         * Sort
         * @default "-date_created"
         */
        sort?: string;
        /**
         * Search
         * @default ""
         */
        search?: string;
        /** Organization */
        organization?: string;
        /** Project */
        project?: string;
        /** State */
        state?: string;
        /**
         * Include Deleted
         * @default false
         */
        include_deleted?: boolean;
        /**
         * Include Scores
         * @default false
         */
        include_scores?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<AssessmentList, HTTPValidationError>({
        path: `/api/internal/assessments`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name CreateAssessment
     * @summary Create Assessment
     * @request POST:/api/internal/assessments
     * @secure
     */
    createAssessment: (data: SchemasV2InternalAssessmentsAssessmentCreate, params: RequestParams = {}) =>
      this.request<TokenResponse, HTTPValidationError>({
        path: `/api/internal/assessments`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name SoftDeleteAssessment
     * @summary Soft Delete Assessment
     * @request DELETE:/api/internal/assessments/{assessment_id}
     * @secure
     */
    softDeleteAssessment: (assessmentId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/internal/assessments/${assessmentId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name UpdateAsessment
     * @summary Update Asessment
     * @request PATCH:/api/internal/assessments/{assessment_id}
     * @secure
     */
    updateAsessment: (assessmentId: string, data: SchemasV2InternalAssessmentsAssessmentUpdate, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/internal/assessments/${assessmentId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name GetNewAssessmentToken
     * @summary Get New Assessment Token
     * @request GET:/api/internal/assessments/{assessment_id}/token
     * @secure
     */
    getNewAssessmentToken: (assessmentId: string, params: RequestParams = {}) =>
      this.request<TokenResponse, HTTPValidationError>({
        path: `/api/internal/assessments/${assessmentId}/token`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name GetAssessmentScoringVersions
     * @summary Get Assessment Scoring Versions
     * @request GET:/api/internal/assessments/{assessment_id}/scores
     * @secure
     */
    getAssessmentScoringVersions: (assessmentId: string, params: RequestParams = {}) =>
      this.request<SchemasV2InternalAssessmentsScoringVersions, HTTPValidationError>({
        path: `/api/internal/assessments/${assessmentId}/scores`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name RecalculateAssessmentScores
     * @summary Recalculate Assessment Scores
     * @request POST:/api/internal/assessments/{assessment_id}/recalculate
     * @secure
     */
    recalculateAssessmentScores: (assessmentId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/internal/assessments/${assessmentId}/recalculate`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name GetAssessmentReportPreview
     * @summary Get Assessment Report Preview
     * @request GET:/api/internal/assessments/{assessment_id}/report/preview
     * @secure
     */
    getAssessmentReportPreview: (
      assessmentId: string,
      query: {
        /** Scoring Version */
        scoring_version: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ReportPreview, HTTPValidationError>({
        path: `/api/internal/assessments/${assessmentId}/report/preview`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name PublishAssessmentReport
     * @summary Publish Assessment Report
     * @request POST:/api/internal/assessments/{assessment_id}/report
     * @secure
     */
    publishAssessmentReport: (
      assessmentId: string,
      data: SchemasV2InternalAssessmentsElraReportData,
      query?: {
        /**
         * Send Email
         * @default false
         */
        send_email?: boolean;
        /**
         * Beta
         * @default false
         */
        beta?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/api/internal/assessments/${assessmentId}/report`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name GetOrganizations
     * @summary Get Organizations
     * @request GET:/api/internal/organizations
     * @secure
     */
    getOrganizations: (params: RequestParams = {}) =>
      this.request<SchemasV2InternalOrganizationsOrganization[], any>({
        path: `/api/internal/organizations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name GetManyBenchmarks
     * @summary Get Many Benchmarks
     * @request GET:/api/internal/benchmarks
     * @secure
     */
    getManyBenchmarks: (
      query?: {
        /**
         * Page
         * @exclusiveMin 0
         * @default 1
         */
        page?: number;
        /**
         * Page Size
         * @exclusiveMin 0
         * @default 10
         */
        page_size?: number;
        /**
         * Sort
         * @default "-date_created"
         */
        sort?: string;
        /** Project */
        project?: string;
        /** Is Root */
        is_root?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<BenchmarkList, HTTPValidationError>({
        path: `/api/internal/benchmarks`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name CreateBenchmark
     * @summary Create Benchmark
     * @request POST:/api/internal/benchmarks
     * @secure
     */
    createBenchmark: (data: SchemasV2InternalBenchmarksCreateBenchmark, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/internal/benchmarks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name UpdateBenchmark
     * @summary Update Benchmark
     * @request PUT:/api/internal/benchmarks/{benchmark_id}
     * @secure
     */
    updateBenchmark: (benchmarkId: string, data: SchemasV2InternalBenchmarksCreateBenchmark, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/internal/benchmarks/${benchmarkId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name DeleteBenchmark
     * @summary Delete Benchmark
     * @request DELETE:/api/internal/benchmarks/{benchmark_id}
     * @secure
     */
    deleteBenchmark: (benchmarkId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/internal/benchmarks/${benchmarkId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name CalculateBenchmarkScores
     * @summary Calculate Benchmark Scores
     * @request GET:/api/internal/benchmarks/calculate
     * @secure
     */
    calculateBenchmarkScores: (
      query: {
        /** Assessment Ids */
        assessment_ids: string;
        /** Scoring Version */
        scoring_version: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CalculateBenchmark, HTTPValidationError>({
        path: `/api/internal/benchmarks/calculate`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name UploadAsset
     * @summary Upload Asset
     * @request POST:/api/internal/assets
     * @secure
     */
    uploadAsset: (data: BodyUploadAssetApiInternalAssetsPost, params: RequestParams = {}) =>
      this.request<AssetCreate, HTTPValidationError>({
        path: `/api/internal/assets`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name ListReportTemplates
     * @summary List Report Templates
     * @request GET:/api/internal/templates/
     * @secure
     */
    listReportTemplates: (
      query?: {
        /** Origin Id */
        origin_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Template[], HTTPValidationError>({
        path: `/api/internal/templates/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name PostReportTemplate
     * @summary Post Report Template
     * @request POST:/api/internal/templates/
     * @secure
     */
    postReportTemplate: (data: TemplateCreate, params: RequestParams = {}) =>
      this.request<Template, HTTPValidationError>({
        path: `/api/internal/templates/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name GetReportTemplate
     * @summary Get Report Template
     * @request GET:/api/internal/templates/{name}
     * @secure
     */
    getReportTemplate: (name: string, params: RequestParams = {}) =>
      this.request<Template, HTTPValidationError>({
        path: `/api/internal/templates/${name}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  external = {
    /**
     * No description
     *
     * @tags External
     * @name ReceiveFormData
     * @summary Receive Form Data
     * @request POST:/api/external/webhooks/forms
     */
    receiveFormData: (
      query: {
        /** Api Key */
        api_key: string;
      },
      data: BodyReceiveFormDataApiExternalWebhooksFormsPost,
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/api/external/webhooks/forms`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags External
     * @name CloneAssessments
     * @summary Clone Assessments
     * @request POST:/api/external/assessments/clone
     * @secure
     */
    cloneAssessments: (
      data: SchemasV2ExternalAssessmentsAssessmentMigrate,
      query?: {
        /**
         * Anonymize Names
         * @default false
         */
        anonymize_names?: boolean;
        /**
         * Anonymize Email
         * @default false
         */
        anonymize_email?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<BulkModifyResponse, HTTPValidationError>({
        path: `/api/external/assessments/clone`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags External
     * @name MoveAssessments
     * @summary Move Assessments
     * @request POST:/api/external/assessments/move
     * @secure
     */
    moveAssessments: (data: SchemasV2ExternalAssessmentsAssessmentMigrate, params: RequestParams = {}) =>
      this.request<BulkModifyResponse, HTTPValidationError>({
        path: `/api/external/assessments/move`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  platform = {
    /**
     * No description
     *
     * @tags Platform
     * @name GetSimulationSchema
     * @summary Get Simulation Schema
     * @request GET:/api/platform/schema
     * @secure
     */
    getSimulationSchema: (
      query?: {
        /** @default "en" */
        lang?: Language;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/api/platform/schema`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Platform
     * @name GetModuleLiteral
     * @summary Get Module Literal
     * @request GET:/api/platform/resources/{module}
     * @secure
     */
    getModuleLiteral: (
      module: string,
      query?: {
        /** Version */
        version?: string;
        /**
         * Lang
         * @default "en"
         */
        lang?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<LiteralResponse, HTTPValidationError>({
        path: `/api/platform/resources/${module}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Platform
     * @name GetOwnAssessment
     * @summary Get Own Assessment
     * @request GET:/api/platform/assessments/{assessment_id}
     * @secure
     */
    getOwnAssessment: (assessmentId: string, params: RequestParams = {}) =>
      this.request<SchemasAssessmentAssessmentResponse, HTTPValidationError>({
        path: `/api/platform/assessments/${assessmentId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Platform
     * @name UpdateOwnAssessment
     * @summary Update Own Assessment
     * @request PATCH:/api/platform/assessments/{assessment_id}
     * @secure
     */
    updateOwnAssessment: (assessmentId: string, data: SchemasAssessmentAssessmentUpdate, params: RequestParams = {}) =>
      this.request<SchemasAssessmentAssessmentResponse, HTTPValidationError>({
        path: `/api/platform/assessments/${assessmentId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Platform
     * @name GetOwnAssessmentStatus
     * @summary Get Own Assessment Status
     * @request GET:/api/platform/assessments/{assessment_id}/status
     * @secure
     */
    getOwnAssessmentStatus: (assessmentId: string, params: RequestParams = {}) =>
      this.request<AssessmentStatusResponse, HTTPValidationError>({
        path: `/api/platform/assessments/${assessmentId}/status`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  system = {
    /**
     * No description
     *
     * @tags System
     * @name GetAssessments
     * @summary Get Assessments
     * @request GET:/api/system/assessments
     * @secure
     */
    getAssessments: (
      query?: {
        /** Project */
        project?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SchemasV2SystemAssessmentsAssessment[], HTTPValidationError>({
        path: `/api/system/assessments`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags System
     * @name GetAssessmentById
     * @summary Get Assessment By Id
     * @request GET:/api/system/assessments/{assessment_id}
     * @secure
     */
    getAssessmentById: (assessmentId: string, params: RequestParams = {}) =>
      this.request<SchemasV2SystemAssessmentsAssessment, HTTPValidationError>({
        path: `/api/system/assessments/${assessmentId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags System
     * @name GetBenchmarks
     * @summary Get Benchmarks
     * @request GET:/api/system/benchmarks
     * @secure
     */
    getBenchmarks: (
      query?: {
        /** Project Id */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SchemasV2SystemBenchmarksBenchmark[], HTTPValidationError>({
        path: `/api/system/benchmarks`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags System
     * @name GetProjects
     * @summary Get Projects
     * @request GET:/api/system/projects
     * @secure
     */
    getProjects: (params: RequestParams = {}) =>
      this.request<SchemasV2SystemProjectsProject[], any>({
        path: `/api/system/projects`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags System
     * @name GetProjectAggregates
     * @summary Get Project Aggregates
     * @request GET:/api/system/projects/aggregates
     * @secure
     */
    getProjectAggregates: (
      query?: {
        /** Project Id */
        project_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProjectAggregates, HTTPValidationError>({
        path: `/api/system/projects/aggregates`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags System
     * @name GetOrgChart
     * @summary Get Org Chart
     * @request GET:/api/system/projects/{project_id}/chart
     * @secure
     */
    getOrgChart: (projectId: string, params: RequestParams = {}) =>
      this.request<ProjectOrgChart, HTTPValidationError>({
        path: `/api/system/projects/${projectId}/chart`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags System
     * @name UpdateProject
     * @summary Update Project
     * @request PATCH:/api/system/projects/{project_id}
     * @secure
     */
    updateProject: (projectId: string, data: SchemasV2SystemProjectsProjectUpdate, params: RequestParams = {}) =>
      this.request<SchemasV2SystemProjectsProject, HTTPValidationError>({
        path: `/api/system/projects/${projectId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags System
     * @name GetReport
     * @summary Get Report
     * @request GET:/api/system/reports/{report_id}
     * @secure
     */
    getReport: (reportId: string, params: RequestParams = {}) =>
      this.request<SchemasV2SystemReportsElraReport | SchemasV2SystemReportsProjectReport | (SchemasV2SystemReportsElraReport & SchemasV2SystemReportsProjectReport), HTTPValidationError>({
        path: `/api/system/reports/${reportId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags System
     * @name GetModuleLiteral
     * @summary Get Module Literal
     * @request GET:/api/system/resources/{module}
     * @secure
     */
    getModuleLiteral: (
      module: string,
      query?: {
        /** Version */
        version?: string;
        /**
         * Lang
         * @default "en"
         */
        lang?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Literal, HTTPValidationError>({
        path: `/api/system/resources/${module}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags System
     * @name GetReportTemplate
     * @summary Get Report Template
     * @request GET:/api/system/templates/{name}
     * @secure
     */
    getReportTemplate: (name: string, params: RequestParams = {}) =>
      this.request<Template, HTTPValidationError>({
        path: `/api/system/templates/${name}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags System
     * @name UpdateUser
     * @summary Update User
     * @request PATCH:/api/system/users/{user_id}
     * @secure
     */
    updateUser: (userId: string, data: SchemasV2SystemUsersUpdateUser, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/system/users/${userId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
